import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: { img: _vm.img, isReadOnly: _vm.isReadOnly },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c("kube-number-field", {
            attrs: { label: "Replicas", isReadOnly: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.replicas,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec, "replicas", $$v)
              },
              expression: "value.object.spec.replicas",
            },
          }),
          _c("kube-number-field", {
            attrs: {
              label: "RevisionHistoryLimit",
              isReadOnly: _vm.isReadOnly,
            },
            model: {
              value: _vm.value.object.spec.revisionHistoryLimit,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec, "revisionHistoryLimit", $$v)
              },
              expression: "value.object.spec.revisionHistoryLimit",
            },
          }),
          _c(VTextField, {
            attrs: { label: "App Name", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.selector.matchLabels.app,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.selector.matchLabels, "app", $$v)
              },
              expression: "value.object.spec.selector.matchLabels.app",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Container Name", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.template.spec.containers[0].name,
              callback: function ($$v) {
                _vm.$set(
                  _vm.value.object.spec.template.spec.containers[0],
                  "name",
                  $$v
                )
              },
              expression: "value.object.spec.template.spec.containers[0].name",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Image", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.template.spec.containers[0].image,
              callback: function ($$v) {
                _vm.$set(
                  _vm.value.object.spec.template.spec.containers[0],
                  "image",
                  $$v
                )
              },
              expression: "value.object.spec.template.spec.containers[0].image",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }