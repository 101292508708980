import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: {
        img: _vm.img,
        isReadOnly: _vm.isReadOnly,
        "validation-lists": _vm.validationLists,
      },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VTextField, {
            attrs: { label: "Provisioner", disabled: _vm.isReadOnly },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function () {
                  return [
                    _c(
                      VIcon,
                      {
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.desDocOpen("#provisioner")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    mdi-help-circle-outline\n                "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.value.object.provisioner,
              callback: function ($$v) {
                _vm.$set(_vm.value.object, "provisioner", $$v)
              },
              expression: "value.object.provisioner",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Parameter Type", disabled: _vm.isReadOnly },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function () {
                  return [
                    _c(
                      VIcon,
                      {
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.desDocOpen("#parameters")
                          },
                        },
                      },
                      [
                        _vm._v(
                          "\n                    mdi-help-circle-outline\n                "
                        ),
                      ]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.value.object.parameters.type,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.parameters, "type", $$v)
              },
              expression: "value.object.parameters.type",
            },
          }),
          _c(VSelect, {
            attrs: {
              label: "Reclaim Policy",
              disabled: _vm.isReadOnly,
              items: _vm.policyList,
            },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function () {
                  return [
                    _c(
                      VIcon,
                      {
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.desDocOpen("#reclaim-policy")
                          },
                        },
                      },
                      [_vm._v("mdi-help-circle-outline")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.value.object.reclaimPolicy,
              callback: function ($$v) {
                _vm.$set(_vm.value.object, "reclaimPolicy", $$v)
              },
              expression: "value.object.reclaimPolicy",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }