import { VIcon } from 'vuetify/lib/components/VIcon';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(VTextField, {
    attrs: { label: _vm.label, disabled: _vm.isReadOnly, type: "number" },
    scopedSlots: _vm._u([
      {
        key: "append-outer",
        fn: function () {
          return [
            _vm.desDoc
              ? _c(
                  VIcon,
                  {
                    attrs: { small: "" },
                    on: {
                      click: function ($event) {
                        return _vm.openDoc(_vm.desDoc)
                      },
                    },
                  },
                  [_vm._v("mdi-help-circle-outline")]
                )
              : _vm._e(),
          ]
        },
        proxy: true,
      },
    ]),
    model: {
      value: _vm.numberVal,
      callback: function ($$v) {
        _vm.numberVal = $$v
      },
      expression: "numberVal",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }