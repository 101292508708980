import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: { img: _vm.img, isReadOnly: _vm.isReadOnly },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VTextField, {
            attrs: { label: "Host", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.hosts[0],
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.hosts, 0, $$v)
              },
              expression: "value.object.spec.hosts[0]",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Http Name", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.http[0].name,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.http[0], "name", $$v)
              },
              expression: "value.object.spec.http[0].name",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }