import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTextarea } from 'vuetify/lib/components/VTextarea';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: {
        img: _vm.img,
        isReadOnly: _vm.isReadOnly,
        "validation-lists": _vm.validationLists,
      },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(
            VRow,
            [
              _c(VCol, { staticClass: "py-0", attrs: { cols: "3" } }, [
                _c("span", [_vm._v("Data")]),
              ]),
              _c(
                VCol,
                { staticClass: "py-0" },
                [
                  _c(VCheckbox, {
                    staticClass: "my-0",
                    attrs: { label: "Use Textarea", dense: "" },
                    model: {
                      value: _vm.useTextarea,
                      callback: function ($$v) {
                        _vm.useTextarea = $$v
                      },
                      expression: "useTextarea",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(VTextField, {
            attrs: { label: "Key", dense: "", disabled: _vm.isReadOnly },
            model: {
              value: _vm.dataKey,
              callback: function ($$v) {
                _vm.dataKey = $$v
              },
              expression: "dataKey",
            },
          }),
          !_vm.useTextarea
            ? _c(VTextField, {
                attrs: {
                  label: "Value",
                  dense: "",
                  disabled: _vm.isReadOnly,
                  hint: "base64 encoding",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addData(_vm.dataKey, _vm.dataValue)
                  },
                },
                model: {
                  value: _vm.dataValue,
                  callback: function ($$v) {
                    _vm.dataValue = $$v
                  },
                  expression: "dataValue",
                },
              })
            : _c(VTextarea, {
                attrs: {
                  label: "Value",
                  dense: "",
                  disabled: _vm.isReadOnly,
                  hint: "base64 encoding",
                },
                on: {
                  keyup: function ($event) {
                    if (
                      !$event.type.indexOf("key") &&
                      _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")
                    )
                      return null
                    return _vm.addData(_vm.dataKey, _vm.dataValue)
                  },
                },
                model: {
                  value: _vm.dataValue,
                  callback: function ($$v) {
                    _vm.dataValue = $$v
                  },
                  expression: "dataValue",
                },
              }),
          _c(
            VRow,
            { attrs: { justify: "end" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mx-5",
                  attrs: {
                    color: "primary",
                    rounded: "",
                    dark: "",
                    disabled: _vm.isReadOnly,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addData(_vm.dataKey, _vm.dataValue)
                    },
                  },
                },
                [_vm._v("Add Data")]
              ),
            ],
            1
          ),
          _c("br"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }