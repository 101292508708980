import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        VDialog,
        {
          attrs: { width: "500" },
          scopedSlots: _vm._u([
            {
              key: "activator",
              fn: function ({ on, attrs }) {
                return [
                  _c(
                    "div",
                    _vm._l(_vm.userDefinedAttributes, function (item, idx) {
                      return _c(
                        "div",
                        { key: idx },
                        [
                          item.type == "String"
                            ? _c(VTextField, {
                                attrs: {
                                  label: item.label,
                                  disabled: _vm.isReadOnly,
                                },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.updateValue(
                                      item.key,
                                      _vm.userDefinedAttrVal[item.key],
                                      item.type
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-outer",
                                      fn: function () {
                                        return [
                                          _c(
                                            VIcon,
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteAttr(item)
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-delete-forever")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.userDefinedAttrVal[item.key],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userDefinedAttrVal,
                                      item.key,
                                      $$v
                                    )
                                  },
                                  expression: "userDefinedAttrVal[item.key]",
                                },
                              })
                            : _vm._e(),
                          item.type == "Number"
                            ? _c(VTextField, {
                                attrs: {
                                  label: item.label,
                                  type: "number",
                                  disabled: _vm.isReadOnly,
                                },
                                on: {
                                  keyup: function ($event) {
                                    return _vm.updateValue(
                                      item.key,
                                      _vm.userDefinedAttrVal[item.key],
                                      item.type
                                    )
                                  },
                                  click: function ($event) {
                                    return _vm.updateValue(
                                      item.key,
                                      _vm.userDefinedAttrVal[item.key],
                                      item.type
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-outer",
                                      fn: function () {
                                        return [
                                          _c(
                                            VIcon,
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteAttr(item)
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-delete-forever")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.userDefinedAttrVal[item.key],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userDefinedAttrVal,
                                      item.key,
                                      $$v
                                    )
                                  },
                                  expression: "userDefinedAttrVal[item.key]",
                                },
                              })
                            : _vm._e(),
                          item.type == "Boolean"
                            ? _c(VCheckbox, {
                                attrs: {
                                  label: item.label,
                                  disabled: _vm.isReadOnly,
                                },
                                on: {
                                  change: function ($event) {
                                    return _vm.updateValue(
                                      item.key,
                                      _vm.userDefinedAttrVal[item.key],
                                      item.type
                                    )
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "append-outer",
                                      fn: function () {
                                        return [
                                          _c(
                                            VIcon,
                                            {
                                              attrs: { small: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.deleteAttr(item)
                                                },
                                              },
                                            },
                                            [_vm._v("mdi-delete-forever")]
                                          ),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  true
                                ),
                                model: {
                                  value: _vm.userDefinedAttrVal[item.key],
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.userDefinedAttrVal,
                                      item.key,
                                      $$v
                                    )
                                  },
                                  expression: "userDefinedAttrVal[item.key]",
                                },
                              })
                            : _vm._e(),
                          item.type == "Key & Value"
                            ? _c(
                                "div",
                                [
                                  _c("span", [_vm._v(_vm._s(item.label))]),
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Key",
                                      disabled: _vm.isReadOnly,
                                    },
                                    scopedSlots: _vm._u(
                                      [
                                        {
                                          key: "append-outer",
                                          fn: function () {
                                            return [
                                              _c(
                                                VIcon,
                                                {
                                                  attrs: { small: "" },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.deleteAttr(
                                                        item
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("mdi-delete-forever")]
                                              ),
                                            ]
                                          },
                                          proxy: true,
                                        },
                                      ],
                                      null,
                                      true
                                    ),
                                    model: {
                                      value: _vm.definedKey,
                                      callback: function ($$v) {
                                        _vm.definedKey = $$v
                                      },
                                      expression: "definedKey",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: {
                                      dense: "",
                                      label: "Value",
                                      disabled: _vm.isReadOnly,
                                    },
                                    on: {
                                      keydown: function ($event) {
                                        if (
                                          !$event.type.indexOf("key") &&
                                          _vm._k(
                                            $event.keyCode,
                                            "enter",
                                            13,
                                            $event.key,
                                            "Enter"
                                          )
                                        )
                                          return null
                                        _vm.updateValue(
                                          _vm.definedKey,
                                          _vm.definedValue,
                                          item.type,
                                          item
                                        )
                                        _vm.definedKey = ""
                                        _vm.definedValue = ""
                                      },
                                    },
                                    model: {
                                      value: _vm.definedValue,
                                      callback: function ($$v) {
                                        _vm.definedValue = $$v
                                      },
                                      expression: "definedValue",
                                    },
                                  }),
                                ],
                                1
                              )
                            : _vm._e(),
                        ],
                        1
                      )
                    }),
                    0
                  ),
                  _c(
                    VBtn,
                    _vm._g(
                      _vm._b(
                        {
                          attrs: {
                            text: "",
                            dark: "",
                            color: "primary",
                            disabled: _vm.isReadOnly,
                          },
                          on: {
                            click: function ($event) {
                              _vm.attrDialog = true
                            },
                          },
                        },
                        "v-btn",
                        attrs,
                        false
                      ),
                      on
                    ),
                    [_vm._v("\n                ADD ATTRIBUTE\n            ")]
                  ),
                ]
              },
            },
          ]),
          model: {
            value: _vm.attrDialog,
            callback: function ($$v) {
              _vm.attrDialog = $$v
            },
            expression: "attrDialog",
          },
        },
        [
          _c(
            VCard,
            [
              _c(
                VCardTitle,
                { staticClass: "headline" },
                [
                  _c(
                    VTabs,
                    [
                      _c(
                        VTab,
                        {
                          on: {
                            click: function ($event) {
                              _vm.isAddAttr = true
                            },
                          },
                        },
                        [_vm._v("ADD ATTRIBUTE")]
                      ),
                      _c(
                        VTab,
                        {
                          on: {
                            click: function ($event) {
                              _vm.isAddAttr = false
                              _vm.attrKey = ""
                              _vm.attrVal = ""
                            },
                          },
                        },
                        [_vm._v("USER DEFINED")]
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _vm.isAddAttr
                ? _c(
                    VCardText,
                    [
                      _c(VAutocomplete, {
                        attrs: {
                          filled: "",
                          label: "Select Attribute",
                          disabled: _vm.isReadOnly,
                          items: _vm.attrList,
                        },
                        model: {
                          value: _vm.searchKey,
                          callback: function ($$v) {
                            _vm.searchKey = $$v
                          },
                          expression: "searchKey",
                        },
                      }),
                      _vm.attrVal
                        ? _c("MonacoEditor", {
                            staticClass: "editor",
                            attrs: { theme: "vs-dark", language: "yaml" },
                            model: {
                              value: _vm.attrVal,
                              callback: function ($$v) {
                                _vm.attrVal = $$v
                              },
                              expression: "attrVal",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  )
                : _c(
                    VCardText,
                    [
                      _c(VTextField, {
                        attrs: {
                          filled: "",
                          label: "Label (e.g. Template Labels)",
                        },
                        model: {
                          value: _vm.attrLabel,
                          callback: function ($$v) {
                            _vm.attrLabel = $$v
                          },
                          expression: "attrLabel",
                        },
                      }),
                      _c(VTextField, {
                        attrs: {
                          filled: "",
                          label:
                            "Attribute Key (e.g. spec.template.metadata.labels)",
                        },
                        model: {
                          value: _vm.attrKey,
                          callback: function ($$v) {
                            _vm.attrKey = $$v
                          },
                          expression: "attrKey",
                        },
                      }),
                      _c(VSelect, {
                        attrs: {
                          filled: "",
                          label: "Attribute Value Type",
                          items: _vm.typeList,
                        },
                        model: {
                          value: _vm.attrValType,
                          callback: function ($$v) {
                            _vm.attrValType = $$v
                          },
                          expression: "attrValType",
                        },
                      }),
                    ],
                    1
                  ),
              _c(
                VCardActions,
                [
                  _c(VSpacer),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "red darken-1",
                        text: "",
                        disabled: _vm.isReadOnly,
                      },
                      on: {
                        click: function ($event) {
                          _vm.attrDialog = false
                        },
                      },
                    },
                    [_vm._v("Cancel")]
                  ),
                  _c(
                    VBtn,
                    {
                      attrs: {
                        color: "green darken-1",
                        text: "",
                        disabled: _vm.isReadOnly,
                      },
                      on: { click: _vm.addAttribute },
                    },
                    [_vm._v("ADD")]
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }