import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticStyle: { width: "300px" },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemTitle, { staticClass: "headline" }, [
                    _vm._v("DestinationRule"),
                  ]),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.closePanel()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VCardText,
                    [
                      _c(
                        VRadioGroup,
                        {
                          attrs: {
                            disabled: _vm.isReadOnly,
                            row: "",
                            dense: "",
                          },
                          model: {
                            value: _vm.value.version,
                            callback: function ($$v) {
                              _vm.$set(_vm.value, "version", $$v)
                            },
                            expression: "value.version",
                          },
                        },
                        [
                          _c(VRadio, {
                            attrs: { label: "canary", value: "canary" },
                          }),
                          _c(VRadio, {
                            attrs: { label: "stable", value: "stable" },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }