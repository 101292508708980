import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticStyle: { width: "300px" },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemTitle, { staticClass: "headline" }, [
                    _vm._v("Route Type"),
                  ]),
                  _c(
                    VBtn,
                    {
                      attrs: { icon: "" },
                      nativeOn: {
                        click: function ($event) {
                          return _vm.closePanel()
                        },
                      },
                    },
                    [
                      _c(VIcon, { attrs: { color: "grey lighten-1" } }, [
                        _vm._v("mdi-close"),
                      ]),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VCardText,
                    [
                      _c(VCheckbox, {
                        attrs: {
                          label: "Mirror Traffic",
                          disabled: _vm.isReadOnly,
                        },
                        model: {
                          value: _vm.isMirror,
                          callback: function ($$v) {
                            _vm.isMirror = $$v
                          },
                          expression: "isMirror",
                        },
                      }),
                      !_vm.isMirror
                        ? _c(VTextField, {
                            attrs: {
                              label: "weight",
                              type: "number",
                              suffix: "%",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.weight,
                              callback: function ($$v) {
                                _vm.weight = $$v
                              },
                              expression: "weight",
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }