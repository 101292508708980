import { VCard } from 'vuetify/lib/components/VCard';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(VSwitch, {
        staticClass: "justify-end",
        staticStyle: {
          padding: "0",
          margin: "0",
          height: "30px",
          "padding-right": "15px",
        },
        attrs: { dense: "", label: _vm.isJson ? "Json" : "Yaml" },
        model: {
          value: _vm.isJson,
          callback: function ($$v) {
            _vm.isJson = $$v
          },
          expression: "isJson",
        },
      }),
      _c(
        VCard,
        { attrs: { disabled: _vm.isReadOnly } },
        [
          !_vm.isJson
            ? _c("codemirror", {
                ref: "myCm",
                staticStyle: { width: "100%", height: "100%" },
                attrs: {
                  options: {
                    theme: "darcula",
                    lineNumbers: true,
                    lineWrapping: true,
                  },
                },
                model: {
                  value: _vm.yamlText,
                  callback: function ($$v) {
                    _vm.yamlText = $$v
                  },
                  expression: "yamlText",
                },
              })
            : _c("vue-json-editor", {
                attrs: { expandedOnStart: true },
                model: {
                  value: _vm.value,
                  callback: function ($$v) {
                    _vm.value = $$v
                  },
                  expression: "value",
                },
              }),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }