var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "sub-controller",
        {
          attrs: { image: _vm.setImage(_vm.image), cloneable: "" },
          on: { clone: _vm.edgeAndElement },
        },
        [_c("circle-element", { attrs: { width: 100, height: 100 } })],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }