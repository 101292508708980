var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "geometry-element",
        {
          attrs: {
            selectable: "",
            movable: "",
            resizable: "",
            connectable: !_vm.canvas.isReadOnlyModel,
            deletable: !_vm.canvas.isReadOnlyModel,
            id: _vm.value.elementView.id,
            x: _vm.value.elementView.x,
            y: _vm.value.elementView.y,
            width: _vm.value.elementView.width,
            height: _vm.value.elementView.height,
            angle: _vm.value.elementView.angle,
            customMoveActionExist: _vm.canvas.isCustomMoveExist,
            label: _vm.name,
            _style: {
              "label-angle": _vm.value.elementView.angle,
              "font-weight": "bold",
              "font-size": "16",
            },
          },
          on: {
            "update:id": function ($event) {
              return _vm.$set(_vm.value.elementView, "id", $event)
            },
            "update:x": function ($event) {
              return _vm.$set(_vm.value.elementView, "x", $event)
            },
            "update:y": function ($event) {
              return _vm.$set(_vm.value.elementView, "y", $event)
            },
            "update:width": function ($event) {
              return _vm.$set(_vm.value.elementView, "width", $event)
            },
            "update:height": function ($event) {
              return _vm.$set(_vm.value.elementView, "height", $event)
            },
            "update:angle": function ($event) {
              return _vm.$set(_vm.value.elementView, "angle", $event)
            },
            customMoveAction: _vm.delayedMove,
            moveShape: _vm.onMoveShape,
            selectShape: _vm.selectedActivity,
            deSelectShape: _vm.deSelectedActivity,
            dblclick: _vm.openPanel,
            addedToGroup: _vm.onAddedToGroup,
            removeShape: _vm.onRemoveShape,
            "update:label": function ($event) {
              _vm.name = $event
            },
            contextmenu: function ($event) {
              $event.preventDefault()
              $event.stopPropagation()
              return _vm.handleClick($event)
            },
          },
        },
        [
          _c("geometry-rect", {
            attrs: {
              _style: {
                "fill-r": 1,
                "fill-cx": 0.1,
                "fill-cy": 0.1,
                "stroke-width": 1.4,
                stroke: "#cccccc",
                fill: "#cccccc",
                "fill-opacity": 1,
                r: "1",
                "z-index": "998",
              },
            },
          }),
          _c(
            "sub-elements",
            [
              _c("multi-user-status-indicator", {
                attrs: {
                  images: _vm.newEditUserImg,
                  "element-height": _vm.value.elementView.height,
                },
              }),
            ],
            1
          ),
          _c("sub-controller", {
            attrs: { image: "terminal.png" },
            on: {
              click: function ($event) {
                $event.preventDefault()
                $event.stopPropagation()
                return _vm.handleClick($event)
              },
            },
          }),
          _c(
            "sub-elements",
            [
              _c("text-element", {
                attrs: {
                  "sub-width": "100%",
                  "sub-height": 25,
                  "sub-top": 0,
                  "sub-left": 0,
                  text: "PVC",
                },
              }),
              _c("image-element", {
                attrs: {
                  image: _vm.imgSrc,
                  "sub-top": 5,
                  "sub-left": 5,
                  "sub-width": 25,
                  "sub-height": 25,
                },
              }),
            ],
            1
          ),
          _vm._l(_vm.filterConnectionTypes, function (connectableType, idx) {
            return _c("k8s-sub-controller", {
              key: idx,
              attrs: {
                element: _vm.value,
                image: connectableType.src,
                type: connectableType.component,
              },
            })
          }),
        ],
        2
      ),
      _vm.propertyPanel
        ? _c("property-panel", {
            attrs: {
              img: _vm.imgSrc,
              validationLists: _vm.filteredElementValidationResults,
              isReadOnly: !_vm.isEditElement,
            },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
      _c("vue-context-menu", {
        ref: "vueSimpleContextMenu",
        attrs: { elementId: _vm.value.elementView.id, options: _vm.menuList },
        on: { "option-clicked": _vm.optionClicked },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }