import { VCardText } from 'vuetify/lib/components/VCard';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          style: { width: 800 },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemTitle, { staticClass: "headline" }, [
                    _vm._v(_vm._s(_vm.titleName)),
                  ]),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _vm.value.sourceElement._type == "VirtualService"
                ? _c(
                    VLayout,
                    [
                      _c(
                        VCardText,
                        [
                          _c(
                            VRadioGroup,
                            {
                              attrs: { row: "", disabled: _vm.isReadOnly },
                              model: {
                                value: _vm.value.sourceElement.routeType,
                                callback: function ($$v) {
                                  _vm.$set(
                                    _vm.value.sourceElement,
                                    "routeType",
                                    $$v
                                  )
                                },
                                expression: "value.sourceElement.routeType",
                              },
                            },
                            [
                              _c(VRadio, {
                                attrs: { label: "weight", value: "weight" },
                              }),
                              _c(VRadio, {
                                attrs: { label: "mirror", value: "mirror" },
                              }),
                            ],
                            1
                          ),
                          _vm.value.sourceElement.routeType == "weight"
                            ? _c(VTextField, {
                                attrs: {
                                  disabled: _vm.isReadOnly,
                                  label: "weight",
                                  type: "number",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }