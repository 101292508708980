var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("codemirror", {
    ref: "myCm",
    attrs: {
      options: {
        theme: "darcula",
        lineNumbers: true,
        lineWrapping: true,
      },
    },
    model: {
      value: _vm.yamlText,
      callback: function ($$v) {
        _vm.yamlText = $$v
      },
      expression: "yamlText",
    },
  })
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }