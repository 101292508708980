import { VCardText } from 'vuetify/lib/components/VCard';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        { attrs: { absolute: "", permanent: "", right: "" } },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VTabs,
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.tabItems, function (tab, idx) {
                  return _c(
                    VTab,
                    {
                      key: idx,
                      on: {
                        click: function ($event) {
                          return _vm.changeType(idx)
                        },
                      },
                    },
                    [_c(VListItemTitle, [_vm._v(_vm._s(tab))])],
                    1
                  )
                }),
                1
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _vm.activeTab == 0
                ? _c(
                    VLayout,
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        VCardText,
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Service Path",
                              hint: "ex) /foo",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.path,
                              callback: function ($$v) {
                                _vm.path = $$v
                              },
                              expression: "path",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  )
                : _c(
                    VLayout,
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        VCardText,
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Host",
                              hint: "ex) foo.bar.com",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.host,
                              callback: function ($$v) {
                                _vm.host = $$v
                              },
                              expression: "host",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }