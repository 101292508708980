import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: { img: _vm.img, isReadOnly: _vm.isReadOnly },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VTextField, {
            attrs: { label: "Project", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.project,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec, "project", $$v)
              },
              expression: "value.object.spec.project",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Repository URL", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.source.repoURL,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.source, "repoURL", $$v)
              },
              expression: "value.object.spec.source.repoURL",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Target Revision", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.source.targetRevision,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.source, "targetRevision", $$v)
              },
              expression: "value.object.spec.source.targetRevision",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Path", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.source.path,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.source, "path", $$v)
              },
              expression: "value.object.spec.source.path",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Destination Server", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.destination.server,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.destination, "server", $$v)
              },
              expression: "value.object.spec.destination.server",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Destination Namespace", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.destination.namespace,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.destination, "namespace", $$v)
              },
              expression: "value.object.spec.destination.namespace",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }