import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _c(
        VRow,
        [
          _c(
            VCol,
            { attrs: { cols: "6", md: "4" } },
            [
              _c(
                VCard,
                {
                  staticClass: "mx-auto",
                  class: _vm.value.name == "default" ? "selected" : "",
                  attrs: {
                    "max-width": "400",
                    "max-height": "400",
                    outlined: "",
                  },
                  on: {
                    click: function ($event) {
                      return _vm.selectCluster({
                        name: "default",
                        cluserAddress: "",
                        apiServer: "",
                        token: "",
                        connection: true,
                      })
                    },
                  },
                },
                [
                  _c(VCardTitle, [
                    _vm._v("\n                    default\n                "),
                  ]),
                  _c(VCardText, [
                    _vm._v(
                      "\n                    API Server : default\n                "
                    ),
                  ]),
                  _c(
                    VRow,
                    [
                      _c(
                        VChip,
                        {
                          staticStyle: {
                            position: "relative",
                            "margin-bottom": "10px",
                            "margin-left": "25px",
                          },
                          attrs: {
                            small: "",
                            color: "green",
                            "text-color": "white",
                          },
                        },
                        [
                          _vm._v(
                            "\n                        connected\n                    "
                          ),
                        ]
                      ),
                      _vm.value.name == "default"
                        ? _c(
                            VIcon,
                            {
                              staticStyle: {
                                position: "absolute",
                                right: "5px",
                                bottom: "5px",
                              },
                              attrs: { large: "", color: "green" },
                            },
                            [_vm._v("check\n                    ")]
                          )
                        : _vm._e(),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _vm._l(_vm.clustersLists, function (item, index) {
            return _c(
              VCol,
              { key: index, attrs: { cols: "6", md: "4" } },
              [
                _c(
                  VCard,
                  {
                    staticClass: "mx-auto",
                    class: item.name == _vm.value.name ? "selected" : "",
                    attrs: {
                      "max-width": "400",
                      "max-height": "400",
                      outlined: "",
                    },
                    on: {
                      click: function ($event) {
                        return _vm.selectCluster(item)
                      },
                    },
                  },
                  [
                    _c(
                      VCardTitle,
                      [
                        _vm._v(
                          "\n                    " +
                            _vm._s(item.name) +
                            "\n                    "
                        ),
                        _c(VSpacer),
                        _c(
                          VBtn,
                          { attrs: { text: "", icon: "" } },
                          [
                            _c(
                              VIcon,
                              {
                                on: {
                                  click: function ($event) {
                                    $event.stopPropagation()
                                    return _vm.deleteCluster(item)
                                  },
                                },
                              },
                              [_vm._v("delete")]
                            ),
                          ],
                          1
                        ),
                      ],
                      1
                    ),
                    _c(VCardText, [
                      _vm._v(
                        "\n                    API Server : " +
                          _vm._s(item.apiServer) +
                          "\n                "
                      ),
                    ]),
                    _c(
                      VRow,
                      [
                        item.connection
                          ? _c(
                              VChip,
                              {
                                staticStyle: {
                                  position: "relative",
                                  "margin-bottom": "10px",
                                  "margin-left": "25px",
                                },
                                attrs: {
                                  small: "",
                                  color: "green",
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        connected\n                    "
                                ),
                              ]
                            )
                          : _c(
                              VChip,
                              {
                                staticStyle: {
                                  position: "relative",
                                  "margin-bottom": "10px",
                                  "margin-left": "25px",
                                },
                                attrs: {
                                  small: "",
                                  color: "red",
                                  "text-color": "white",
                                },
                              },
                              [
                                _vm._v(
                                  "\n                        disconnected\n                    "
                                ),
                              ]
                            ),
                        item.name == _vm.value.name
                          ? _c(
                              VIcon,
                              {
                                staticStyle: {
                                  position: "absolute",
                                  right: "5px",
                                  bottom: "5px",
                                },
                                attrs: { large: "", color: "green" },
                              },
                              [_vm._v("check\n                    ")]
                            )
                          : _vm._e(),
                      ],
                      1
                    ),
                  ],
                  1
                ),
              ],
              1
            )
          }),
          _c(
            VCol,
            { attrs: { cols: "6", md: "4" } },
            [
              !_vm.tokenDialog
                ? _c(
                    VCard,
                    {
                      staticClass: "mx-auto",
                      staticStyle: {
                        height: "108px",
                        "text-align": "center",
                        "line-height": "108px",
                        "font-size": "50px",
                        "font-weight": "100",
                      },
                      attrs: { outlined: "" },
                      on: {
                        click: function ($event) {
                          ;(_vm.tokenDialog = true), (_vm.connectable = false)
                        },
                      },
                    },
                    [_vm._v("+\n            ")]
                  )
                : _vm._e(),
              _c(
                "div",
                [
                  _vm.tokenDialog
                    ? _c(
                        VCard,
                        { attrs: { "max-width": "350", outlined: "" } },
                        [
                          _c(VCardTitle, { staticClass: "headline" }, [
                            _vm._v("Cluster"),
                          ]),
                          _c(
                            VCardText,
                            [
                              _c(VTextField, {
                                attrs: { label: "Name", required: "" },
                                model: {
                                  value: _vm.clusterName,
                                  callback: function ($$v) {
                                    _vm.clusterName = $$v
                                  },
                                  expression: "clusterName",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "Cluster Address",
                                  required: "",
                                  hint: "kubectl clsuter-info >> Kubernetes control plane url",
                                },
                                model: {
                                  value: _vm.clusterAddress,
                                  callback: function ($$v) {
                                    _vm.clusterAddress = $$v
                                  },
                                  expression: "clusterAddress",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "API Server",
                                  required: "",
                                  hint: "Ex) https://api.k8s.bzdvops.com",
                                },
                                model: {
                                  value: _vm.apiServer,
                                  callback: function ($$v) {
                                    _vm.apiServer = $$v
                                  },
                                  expression: "apiServer",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "TOKEN",
                                  required: "",
                                  outline: "",
                                },
                                model: {
                                  value: _vm.kuberToken,
                                  callback: function ($$v) {
                                    _vm.kuberToken = $$v
                                  },
                                  expression: "kuberToken",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "Namespace",
                                  required: "",
                                  outline: "",
                                },
                                model: {
                                  value: _vm.namespace,
                                  callback: function ($$v) {
                                    _vm.namespace = $$v
                                  },
                                  expression: "namespace",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            VCardActions,
                            [
                              _vm.waitConnetionTest
                                ? _c(VProgressCircular, {
                                    attrs: {
                                      indeterminate: "",
                                      color: "primary",
                                    },
                                  })
                                : _c(
                                    VChip,
                                    {
                                      staticClass: "ma-2",
                                      attrs: {
                                        "text-color": "black",
                                        color: "white",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.dialogConnectionTest()
                                        },
                                      },
                                    },
                                    [
                                      _c(
                                        VAvatar,
                                        { attrs: { left: "" } },
                                        [
                                          _c(
                                            VIcon,
                                            {
                                              attrs: {
                                                color: _vm.dialogBtnColor,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                "mdi-checkbox-marked-circle"
                                              ),
                                            ]
                                          ),
                                        ],
                                        1
                                      ),
                                      _vm._v(
                                        "\n                            validation\n                        "
                                      ),
                                    ],
                                    1
                                  ),
                              _c(VSpacer),
                              _c(
                                VBtn,
                                {
                                  attrs: { text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.closeToken()
                                    },
                                  },
                                },
                                [_vm._v("Cancel")]
                              ),
                              _c(
                                VBtn,
                                {
                                  attrs: { color: "primary", text: "" },
                                  on: {
                                    click: function ($event) {
                                      return _vm.save()
                                    },
                                  },
                                },
                                [_vm._v("Save")]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ],
            1
          ),
        ],
        2
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }