import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: {
        img: _vm.img,
        isReadOnly: _vm.isReadOnly,
        "validation-lists": _vm.validationLists,
      },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _vm.value.status
            ? _c(
                VTabs,
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.tabItems, function (tab, idx) {
                  return _c(
                    VTab,
                    { key: idx },
                    [_c(VListItemTitle, [_vm._v(_vm._s(tab))])],
                    1
                  )
                }),
                1
              )
            : _c(VListItemTitle, { staticClass: "headline" }, [
                _vm._v(
                  "\n            " + _vm._s(_vm.value._type) + "\n        "
                ),
              ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-layout" },
        [
          _vm.value.status && _vm.activeTab == 0
            ? _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VFlex,
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c("tree-view", {
                                attrs: {
                                  data: _vm.status,
                                  options: {
                                    rootObjectKey: "status",
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VFlex,
                    { staticStyle: { width: "180px" }, attrs: { shrink: "" } },
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c(VTextField, {
                                attrs: {
                                  label: "Name",
                                  autofocus: "",
                                  disabled: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.object.metadata.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.metadata,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression: "value.object.metadata.name",
                                },
                              }),
                              _c(VSelect, {
                                attrs: {
                                  label: "Access Modes",
                                  disabled: _vm.isReadOnly,
                                  items: _vm.accessModeList,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-outer",
                                    fn: function () {
                                      return [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: { small: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.desDocOpen(
                                                  "#access-modes-1"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-help-circle-outline")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.value.object.spec.accessModes,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.spec,
                                      "accessModes",
                                      $$v
                                    )
                                  },
                                  expression: "value.object.spec.accessModes",
                                },
                              }),
                              _c(VLabel, [_vm._v("Storage")]),
                              _c(
                                VRow,
                                [
                                  _c(
                                    VCol,
                                    {
                                      staticClass: "py-0",
                                      attrs: { cols: "6" },
                                    },
                                    [
                                      _c(VTextField, {
                                        attrs: {
                                          disabled: _vm.isReadOnly,
                                          type: "number",
                                        },
                                        model: {
                                          value: _vm.storageVolume,
                                          callback: function ($$v) {
                                            _vm.storageVolume = $$v
                                          },
                                          expression: "storageVolume",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { staticClass: "py-0" },
                                    [
                                      _c(VSelect, {
                                        attrs: {
                                          items: _vm.units,
                                          disabled: _vm.isReadOnly,
                                        },
                                        model: {
                                          value: _vm.storageUnit,
                                          callback: function ($$v) {
                                            _vm.storageUnit = $$v
                                          },
                                          expression: "storageUnit",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(VSelect, {
                                attrs: {
                                  label: "Volume Mode",
                                  disabled: _vm.isReadOnly,
                                  items: _vm.volumeModeList,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-outer",
                                    fn: function () {
                                      return [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: { small: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.desDocOpen(
                                                  "#volume-modes"
                                                )
                                              },
                                            },
                                          },
                                          [_vm._v("mdi-help-circle-outline")]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.value.object.spec.volumeMode,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.spec,
                                      "volumeMode",
                                      $$v
                                    )
                                  },
                                  expression: "value.object.spec.volumeMode",
                                },
                              }),
                              _c("kube-attr-field", {
                                attrs: { isReadOnly: _vm.isReadOnly },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    [
                      _c("kube-yaml-editor", {
                        attrs: { isReadOnly: _vm.isReadOnly },
                        model: {
                          value: _vm.value.object,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "object", $$v)
                          },
                          expression: "value.object",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }