import { VAutocomplete } from 'vuetify/lib/components/VAutocomplete';
import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardActions } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VChip } from 'vuetify/lib/components/VChip';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VDialog } from 'vuetify/lib/components/VDialog';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VImg } from 'vuetify/lib/components/VImg';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemContent } from 'vuetify/lib/components/VList';
import { VListItemSubtitle } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VOverlay } from 'vuetify/lib/components/VOverlay';
import { VProgressCircular } from 'vuetify/lib/components/VProgressCircular';
import { VRadio } from 'vuetify/lib/components/VRadioGroup';
import { VRadioGroup } from 'vuetify/lib/components/VRadioGroup';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VSnackbar } from 'vuetify/lib/components/VSnackbar';
import { VSpacer } from 'vuetify/lib/components/VGrid';
import { VSpeedDial } from 'vuetify/lib/components/VSpeedDial';
import { VStepper } from 'vuetify/lib/components/VStepper';
import { VStepperContent } from 'vuetify/lib/components/VStepper';
import { VStepperHeader } from 'vuetify/lib/components/VStepper';
import { VStepperItems } from 'vuetify/lib/components/VStepper';
import { VStepperStep } from 'vuetify/lib/components/VStepper';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VToolbar } from 'vuetify/lib/components/VToolbar';
import { VToolbarItems } from 'vuetify/lib/components/VToolbar';
import { VToolbarTitle } from 'vuetify/lib/components/VToolbar';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
import { VTreeview } from 'vuetify/lib/components/VTreeview';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "page", class: { embedded: _vm.embedded } },
    [
      _c("separate-panel-components", {
        attrs: {
          min: _vm.mainSeparatePanel.min,
          max: _vm.mainSeparatePanel.max,
          triggerLength: 5,
          paneLengthPercent: _vm.mainSeparatePanel.current,
          inBoundSeparatePanel: false,
        },
        on: {
          "update:paneLengthPercent": function ($event) {
            return _vm.$set(_vm.mainSeparatePanel, "current", $event)
          },
          "update:pane-length-percent": function ($event) {
            return _vm.$set(_vm.mainSeparatePanel, "current", $event)
          },
          close: function ($event) {
            return _vm.closeSeparatePanel()
          },
        },
        scopedSlots: _vm._u([
          {
            key: "one",
            fn: function () {
              return [
                _c("div", { staticStyle: { width: "100%", height: "100%" } }, [
                  _c(
                    "div",
                    { staticClass: "canvas-panel", staticStyle: { left: "0" } },
                    [
                      _vm.showOverlay
                        ? _c(
                            VOverlay,
                            [
                              _c(
                                VCol,
                                { attrs: { align: "center" } },
                                [
                                  _c("div", [_vm._v(_vm._s(_vm.showOverlay))]),
                                  _c(
                                    VProgressCircular,
                                    {
                                      attrs: { indeterminate: "", size: "64" },
                                    },
                                    [
                                      _c(VBtn, {
                                        attrs: { text: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.closeOverlay()
                                          },
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          )
                        : _vm._e(),
                      _c(
                        VLayout,
                        { attrs: { right: "" } },
                        [
                          _vm.value
                            ? _c(
                                "opengraph",
                                {
                                  key: _vm.openGraphRenderKey,
                                  ref: "opengraph",
                                  attrs: {
                                    width: 100000,
                                    height: 100000,
                                    sliderLocationScale:
                                      _vm.sliderLocationScale,
                                    "focus-canvas-on-select": "",
                                    wheelScalable: "",
                                    labelEditable: true,
                                    dragPageMovable: _vm.dragPageMovable,
                                    enableContextmenu: false,
                                    automaticGuidance: _vm.automaticGuidance,
                                    enableRootContextmenu: false,
                                    enableHotkeyCtrlC: false,
                                    enableHotkeyCtrlV: false,
                                    enableHotkeyDelete: false,
                                    enableHotkeyCtrlZ: false,
                                    enableHotkeyCtrlD: false,
                                    enableHotkeyCtrlG: false,
                                    slider: true,
                                    movable: !_vm.isReadOnlyModel,
                                    resizable: !_vm.isReadOnlyModel,
                                    selectable: true,
                                    connectable: !_vm.isReadOnlyModel,
                                    autoSliderUpdate: true,
                                    imageBase: _vm.imageBase,
                                  },
                                  on: {
                                    "update:sliderLocationScale": function (
                                      $event
                                    ) {
                                      _vm.sliderLocationScale = $event
                                    },
                                    connectShape: _vm.onConnectShape,
                                    canvasReady: _vm.bindEvents,
                                  },
                                },
                                [
                                  _vm._l(
                                    Object.keys(_vm.getValue.elements),
                                    function (elementId) {
                                      return _vm.getValue &&
                                        _vm.getValue.elements &&
                                        typeof _vm.getValue.elements == "object"
                                        ? _c(
                                            "div",
                                            [
                                              elementId &&
                                              _vm.getValue.elements[elementId]
                                                ? _c(
                                                    _vm.getComponentByClassName(
                                                      _vm.getValue.elements[
                                                        elementId
                                                      ]._type
                                                    ),
                                                    {
                                                      ref: elementId,
                                                      refInFor: true,
                                                      tag: "component",
                                                      attrs: {
                                                        value:
                                                          _vm.getValue.elements[
                                                            elementId
                                                          ],
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.getValue
                                                                .elements,
                                                              elementId,
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                  _vm._l(
                                    Object.keys(_vm.getValue.relations),
                                    function (relationId) {
                                      return _vm.getValue &&
                                        _vm.getValue.relations &&
                                        typeof _vm.getValue.relations ==
                                          "object"
                                        ? _c(
                                            "div",
                                            [
                                              relationId &&
                                              _vm.getValue.relations[relationId]
                                                ? _c(
                                                    _vm.getComponentByClassName(
                                                      _vm.getValue.relations[
                                                        relationId
                                                      ]._type
                                                    ),
                                                    {
                                                      ref: relationId,
                                                      refInFor: true,
                                                      tag: "component",
                                                      attrs: {
                                                        value:
                                                          _vm.getValue
                                                            .relations[
                                                            relationId
                                                          ],
                                                      },
                                                      on: {
                                                        "update:value":
                                                          function ($event) {
                                                            return _vm.$set(
                                                              _vm.getValue
                                                                .relations,
                                                              relationId,
                                                              $event
                                                            )
                                                          },
                                                      },
                                                    }
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          )
                                        : _vm._e()
                                    }
                                  ),
                                ],
                                2
                              )
                            : _vm._e(),
                          !_vm.embedded
                            ? _c(
                                VLayout,
                                [
                                  _c(
                                    VFlex,
                                    [
                                      _c(
                                        VRow,
                                        {
                                          staticClass: "gs-modeling-undo-redo",
                                        },
                                        [
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "gs-model-z-index-2 gs-undo-opacity-hover",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isUndoDisabled,
                                                                text: "",
                                                                small: "",
                                                                right: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.undo()
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  medium: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-undo"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                695928026
                                              ),
                                            },
                                            [_c("span", [_vm._v("Undo")])]
                                          ),
                                          _c(
                                            VTooltip,
                                            {
                                              attrs: { bottom: "" },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "gs-model-z-index-2 gs-redo-opacity-hover",
                                                              attrs: {
                                                                disabled:
                                                                  _vm.isRedoDisabled,
                                                                text: "",
                                                                small: "",
                                                                right: "",
                                                              },
                                                              nativeOn: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.redo()
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(
                                                              VIcon,
                                                              {
                                                                attrs: {
                                                                  medium: "",
                                                                },
                                                              },
                                                              [
                                                                _vm._v(
                                                                  "mdi-redo"
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                237927002
                                              ),
                                            },
                                            [_c("span", [_vm._v("Redo")])]
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          _vm.embedded
                            ? _c(
                                VFlex,
                                {
                                  staticStyle: {
                                    justify: "end",
                                    align: "start",
                                  },
                                },
                                [
                                  _c(
                                    VBtn,
                                    _vm._g(
                                      {
                                        staticStyle: {
                                          position: "absolute",
                                          top: "26px",
                                          right: "60px",
                                        },
                                        attrs: { text: "", small: "" },
                                        on: {
                                          click: function ($event) {
                                            return _vm.openCommandViewer()
                                          },
                                        },
                                      },
                                      _vm.on
                                    ),
                                    [
                                      _c(VIcon, [
                                        _vm._v("mdi-code-greater-than"),
                                      ]),
                                      _c("div", [_vm._v("KUBECTL")]),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.embedded
                            ? _c(
                                VFlex,
                                { staticStyle: { "min-width": "100%" } },
                                [
                                  _c(
                                    VRow,
                                    {
                                      attrs: {
                                        justify: "center",
                                        align: "center",
                                      },
                                    },
                                    [
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "kubernetes-mobile-home-button",
                                        },
                                        [
                                          _c(
                                            "router-link",
                                            { attrs: { to: "/" } },
                                            [
                                              _c(
                                                VIcon,
                                                {
                                                  staticStyle: {
                                                    height: "24px",
                                                    "margin-top": "38px",
                                                    "margin-right": "5px",
                                                  },
                                                },
                                                [
                                                  _vm._v(
                                                    "\n                                            mdi-home\n                                        "
                                                  ),
                                                ]
                                              ),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                      _c(VTextField, {
                                        staticClass:
                                          "k8s-is-mobile-project-name",
                                        staticStyle: {
                                          "margin-right": "5px",
                                          "margin-top": "40px",
                                          "max-width": "140px",
                                          "z-index": "1",
                                        },
                                        attrs: {
                                          label: "Project Name",
                                          disabled: _vm.isReadOnly,
                                          dense: "",
                                        },
                                        model: {
                                          value: _vm.projectName,
                                          callback: function ($$v) {
                                            _vm.projectName = $$v
                                          },
                                          expression: "projectName",
                                        },
                                      }),
                                      _c(
                                        "div",
                                        {
                                          staticClass:
                                            "gs-model-z-index-1 k8s-is-not-mobile",
                                        },
                                        [
                                          !_vm.parents
                                            ? _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    "open-on-hover": "",
                                                    left: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          attrs,
                                                          on,
                                                        }) {
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "k8s-hide-cluster-btn",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "5px",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _vm.loadTerminal
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          VProgressCircular,
                                                                          {
                                                                            attrs:
                                                                              {
                                                                                indeterminate:
                                                                                  "",
                                                                                color:
                                                                                  "primary",
                                                                              },
                                                                          }
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    "mdi-server-network"
                                                                  ),
                                                                ]),
                                                                !_vm.loadTerminal
                                                                  ? _c(
                                                                      "div",
                                                                      {
                                                                        staticClass:
                                                                          "k8s-hide-cluster",
                                                                      },
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            _vm
                                                                              .clusterInfo
                                                                              .name
                                                                              ? _vm
                                                                                  .clusterInfo
                                                                                  .name
                                                                              : "Cluster"
                                                                          )
                                                                        ),
                                                                      ]
                                                                    )
                                                                  : _vm._e(),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    2349354714
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.clusterItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionCluster(
                                                                  item.title,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.parents
                                            ? _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    "open-on-hover": "",
                                                    left: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({
                                                          attrs,
                                                          on,
                                                        }) {
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                _vm._b(
                                                                  {
                                                                    staticClass:
                                                                      "k8s-hide-reverse-btn",
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "5px",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                  },
                                                                  "v-btn",
                                                                  attrs,
                                                                  false
                                                                ),
                                                                on
                                                              ),
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    "mdi-cached"
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "k8s-hide-reverse",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "Reverse"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    3904299561
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.reverseItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionReverse(
                                                                  item.title,
                                                                  index
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          !_vm.parents
                                            ? _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    "open-on-hover": "",
                                                    left: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "k8s-hide-gitops-btn",
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "margin-top":
                                                                      "15px",
                                                                  },
                                                                  attrs: {
                                                                    text: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openCodeViewer()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c("Icon", {
                                                                  staticClass:
                                                                    "gs-icon-style",
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "2px",
                                                                    height:
                                                                      "22px",
                                                                    width:
                                                                      "22px",
                                                                  },
                                                                  attrs: {
                                                                    icon: "icomoon-free:git",
                                                                  },
                                                                }),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "k8s-hide-gitops",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "GITOPS"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    54624324
                                                  ),
                                                },
                                                [
                                                  _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.gitOpsItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionSelect(
                                                                  item.title
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                            item.check
                                                              ? _c(
                                                                  VIcon,
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-left":
                                                                          "5px",
                                                                      },
                                                                    attrs: {
                                                                      color:
                                                                        "green",
                                                                    },
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "\n                                                    mdi-check-decagram\n                                                "
                                                                    ),
                                                                  ]
                                                                )
                                                              : _vm._e(),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  ),
                                                ],
                                                1
                                              )
                                            : _vm._e(),
                                          _c(
                                            VMenu,
                                            {
                                              attrs: {
                                                "offset-y": "",
                                                "open-on-hover": "",
                                                left: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _vm.isReadOnlyModel
                                                          ? _c(
                                                              VBtn,
                                                              {
                                                                staticClass:
                                                                  "k8s-hide-fork-btn",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "5px",
                                                                  "margin-top":
                                                                    "15px",
                                                                },
                                                                attrs: {
                                                                  text: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.saveComposition(
                                                                        "fork"
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.icon
                                                                        .fork
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "k8s-hide-fork",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "FORK"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _c(
                                                              VBtn,
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "k8s-hide-save-btn",
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "margin-top":
                                                                      "15px",
                                                                  },
                                                                  attrs: {
                                                                    text: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.saveComposition(
                                                                          "save"
                                                                        )
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      _vm.icon
                                                                        .save
                                                                    )
                                                                  ),
                                                                ]),
                                                                _c(
                                                                  "div",
                                                                  {
                                                                    staticClass:
                                                                      "k8s-hide-save",
                                                                  },
                                                                  [
                                                                    _vm._v(
                                                                      "SAVE"
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1935025293
                                              ),
                                            },
                                            [
                                              !_vm.parents
                                                ? _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.saveItems,
                                                      function (item, index) {
                                                        return !_vm.isClazzModeling
                                                          ? _c(
                                                              VListItem,
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.functionSelect(
                                                                        item.title,
                                                                        index
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          : _vm._e()
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.isOwnModel &&
                                          _vm.isServerModel &&
                                          !_vm.isReadOnlyModel &&
                                          !_vm.parents
                                            ? _c(VMenu, {
                                                attrs: {
                                                  "offset-y": "",
                                                  "open-on-hover": "",
                                                  left: "",
                                                },
                                                scopedSlots: _vm._u(
                                                  [
                                                    {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            VBtn,
                                                            _vm._g(
                                                              {
                                                                staticClass:
                                                                  "k8s-hide-share-btn",
                                                                staticStyle: {
                                                                  "margin-right":
                                                                    "5px",
                                                                  "margin-top":
                                                                    "15px",
                                                                },
                                                                attrs: {
                                                                  text: "",
                                                                },
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.openInviteUsers()
                                                                    },
                                                                },
                                                              },
                                                              on
                                                            ),
                                                            [
                                                              _c(VIcon, [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    _vm.icon
                                                                      .share
                                                                  )
                                                                ),
                                                              ]),
                                                              _c(
                                                                "div",
                                                                {
                                                                  staticClass:
                                                                    "k8s-hide-share",
                                                                },
                                                                [
                                                                  _vm._v(
                                                                    "SHARE"
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    },
                                                  ],
                                                  null,
                                                  false,
                                                  604610746
                                                ),
                                              })
                                            : _vm._e(),
                                          _c(
                                            VMenu,
                                            {
                                              attrs: {
                                                "offset-y": "",
                                                "open-on-hover": "",
                                                left: "",
                                              },
                                              scopedSlots: _vm._u(
                                                [
                                                  {
                                                    key: "activator",
                                                    fn: function ({ on }) {
                                                      return [
                                                        _c(
                                                          VBtn,
                                                          _vm._g(
                                                            {
                                                              staticClass:
                                                                "k8s-hide-code-btn",
                                                              staticStyle: {
                                                                "margin-right":
                                                                  "5px",
                                                                "margin-top":
                                                                  "15px",
                                                              },
                                                              attrs: {
                                                                color:
                                                                  "primary",
                                                                text: "",
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    return _vm.openCodeViewer()
                                                                  },
                                                              },
                                                            },
                                                            on
                                                          ),
                                                          [
                                                            _c(VIcon, [
                                                              _vm._v(
                                                                " " +
                                                                  _vm._s(
                                                                    _vm.icon
                                                                      .code
                                                                  )
                                                              ),
                                                            ]),
                                                            _c(
                                                              "div",
                                                              {
                                                                staticClass:
                                                                  "k8s-hide-code",
                                                              },
                                                              [_vm._v("CODE")]
                                                            ),
                                                          ],
                                                          1
                                                        ),
                                                      ]
                                                    },
                                                  },
                                                ],
                                                null,
                                                false,
                                                1108565834
                                              ),
                                            },
                                            [
                                              !_vm.parents
                                                ? _c(
                                                    VList,
                                                    _vm._l(
                                                      _vm.codeItems,
                                                      function (item, index) {
                                                        return _c(
                                                          VListItem,
                                                          {
                                                            key: index,
                                                            on: {
                                                              click: function (
                                                                $event
                                                              ) {
                                                                return _vm.functionSelect(
                                                                  item.title
                                                                )
                                                              },
                                                            },
                                                          },
                                                          [
                                                            _c(
                                                              VListItemTitle,
                                                              [
                                                                _vm._v(
                                                                  _vm._s(
                                                                    item.title
                                                                  )
                                                                ),
                                                              ]
                                                            ),
                                                          ],
                                                          1
                                                        )
                                                      }
                                                    ),
                                                    1
                                                  )
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                          _vm.isReadOnlyModel
                                            ? _c(
                                                VMenu,
                                                {
                                                  attrs: {
                                                    "offset-y": "",
                                                    "open-on-hover": "",
                                                    left: "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                {
                                                                  staticClass:
                                                                    "k8s-hide-code-btn",
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "margin-top":
                                                                      "15px",
                                                                  },
                                                                  attrs: {
                                                                    color: _vm
                                                                      .joinRequestedText
                                                                      .show
                                                                      ? "primary"
                                                                      : "success",
                                                                    text: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.requestInviteUser()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _vm
                                                                  .joinRequestedText
                                                                  .show
                                                                  ? _c(
                                                                      "div",
                                                                      [
                                                                        _c(
                                                                          VIcon,
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .icon
                                                                                  .join
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _vm._e(),
                                                                _vm._v(
                                                                  "\n                                                " +
                                                                    _vm._s(
                                                                      _vm
                                                                        .joinRequestedText
                                                                        .text
                                                                    ) +
                                                                    "\n                                            "
                                                                ),
                                                              ]
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    994349546
                                                  ),
                                                },
                                                [_c(VList)],
                                                1
                                              )
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    "div",
                                    { staticClass: "k8s-is-mobile" },
                                    [
                                      _c(
                                        VSpeedDial,
                                        {
                                          staticStyle: {
                                            position: "fixed",
                                            bottom: "50px",
                                            right: "50px",
                                          },
                                          scopedSlots: _vm._u(
                                            [
                                              {
                                                key: "activator",
                                                fn: function () {
                                                  return [
                                                    _c(
                                                      VBtn,
                                                      {
                                                        attrs: {
                                                          color:
                                                            "blue darken-2",
                                                          dark: "",
                                                          fab: "",
                                                          small: "",
                                                        },
                                                        model: {
                                                          value: _vm.fab,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.fab = $$v
                                                          },
                                                          expression: "fab",
                                                        },
                                                      },
                                                      [
                                                        _vm.fab
                                                          ? _c(VIcon, [
                                                              _vm._v(
                                                                "\n                                                mdi-playlist-remove\n                                            "
                                                              ),
                                                            ])
                                                          : _c(VIcon, [
                                                              _vm._v(
                                                                "\n                                                mdi-playlist-check\n                                            "
                                                              ),
                                                            ]),
                                                      ],
                                                      1
                                                    ),
                                                  ]
                                                },
                                                proxy: true,
                                              },
                                            ],
                                            null,
                                            false,
                                            3558508514
                                          ),
                                          model: {
                                            value: _vm.fab,
                                            callback: function ($$v) {
                                              _vm.fab = $$v
                                            },
                                            expression: "fab",
                                          },
                                        },
                                        [
                                          _c(
                                            VRow,
                                            {
                                              staticClass:
                                                "k8s-mobile-action-btn",
                                            },
                                            [
                                              !_vm.parents
                                                ? _c(
                                                    VMenu,
                                                    {
                                                      staticStyle: {
                                                        margin:
                                                          "0px !important",
                                                      },
                                                      attrs: { "offset-y": "" },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              attrs,
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  VBtn,
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          text: "",
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm.loadTerminal
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              VProgressCircular,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    indeterminate:
                                                                                      "",
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _vm._e(),
                                                                    _c(
                                                                      VIcon,
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-server-network"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    !_vm.loadTerminal
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .clusterInfo
                                                                                  .name
                                                                                  ? _vm
                                                                                      .clusterInfo
                                                                                      .name
                                                                                  : "Cluster"
                                                                              )
                                                                            ),
                                                                          ]
                                                                        )
                                                                      : _vm._e(),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        645973577
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        VList,
                                                        [
                                                          _c(
                                                            VListItem,
                                                            [
                                                              _c(
                                                                VListItemTitle,
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        "Terminal"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.terminal()
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VListItem,
                                                            [
                                                              _c(
                                                                VListItemTitle,
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        "Sync"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.deployDialog = true
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VListItem,
                                                            [
                                                              _c(
                                                                VListItemTitle,
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        "Cluster"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        _vm.clusterDialog = true
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.parents
                                                ? _c(
                                                    VMenu,
                                                    {
                                                      staticStyle: {
                                                        margin:
                                                          "0px !important",
                                                      },
                                                      attrs: {
                                                        "open-on-hover": "",
                                                        "offset-y": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              attrs,
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  VBtn,
                                                                  _vm._g(
                                                                    _vm._b(
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-right":
                                                                              "5px",
                                                                            "margin-top":
                                                                              "15px",
                                                                          },
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          text: "",
                                                                        },
                                                                      },
                                                                      "v-btn",
                                                                      attrs,
                                                                      false
                                                                    ),
                                                                    on
                                                                  ),
                                                                  [
                                                                    _c(
                                                                      VIcon,
                                                                      [
                                                                        _vm._v(
                                                                          "mdi-cached"
                                                                        ),
                                                                      ]
                                                                    ),
                                                                    _c("div", [
                                                                      _vm._v(
                                                                        "Reverse"
                                                                      ),
                                                                    ]),
                                                                  ],
                                                                  1
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        342348051
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        VList,
                                                        [
                                                          _c(
                                                            VListItem,
                                                            [
                                                              _c(
                                                                VListItemTitle,
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        "From Cluster"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.drawFromCluster()
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                          _c(
                                                            VListItem,
                                                            [
                                                              _c(
                                                                VListItemTitle,
                                                                {
                                                                  domProps: {
                                                                    textContent:
                                                                      _vm._s(
                                                                        "From Local"
                                                                      ),
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.yamlModalShow()
                                                                      },
                                                                  },
                                                                }
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ],
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _c(
                                                VMenu,
                                                {
                                                  staticStyle: {
                                                    margin: "0px !important",
                                                  },
                                                  attrs: {
                                                    "open-on-hover": "",
                                                    "offset-y": "",
                                                  },
                                                  scopedSlots: _vm._u(
                                                    [
                                                      {
                                                        key: "activator",
                                                        fn: function ({ on }) {
                                                          return [
                                                            _c(
                                                              VBtn,
                                                              _vm._g(
                                                                {
                                                                  staticStyle: {
                                                                    "margin-right":
                                                                      "5px",
                                                                    "margin-top":
                                                                      "15px",
                                                                  },
                                                                  attrs: {
                                                                    color:
                                                                      "primary",
                                                                    small: "",
                                                                    text: "",
                                                                  },
                                                                  on: {
                                                                    click:
                                                                      function (
                                                                        $event
                                                                      ) {
                                                                        return _vm.openCodeViewer()
                                                                      },
                                                                  },
                                                                },
                                                                on
                                                              ),
                                                              [
                                                                _c(VIcon, [
                                                                  _vm._v(
                                                                    " " +
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .code
                                                                      )
                                                                  ),
                                                                ]),
                                                                _vm._v(
                                                                  "\n                                                    CODE\n                                                "
                                                                ),
                                                              ],
                                                              1
                                                            ),
                                                          ]
                                                        },
                                                      },
                                                    ],
                                                    null,
                                                    false,
                                                    448176156
                                                  ),
                                                },
                                                [
                                                  !_vm.parents
                                                    ? _c(
                                                        VList,
                                                        _vm._l(
                                                          _vm.codeItems,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VListItem,
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.functionSelect(
                                                                        item.title
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      )
                                                    : _vm._e(),
                                                ],
                                                1
                                              ),
                                              !_vm.parents
                                                ? _c(
                                                    VMenu,
                                                    {
                                                      staticStyle: {
                                                        margin:
                                                          "0px !important",
                                                      },
                                                      attrs: {
                                                        "open-on-hover": "",
                                                        "offset-y": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _c(
                                                                  VBtn,
                                                                  _vm._g(
                                                                    {
                                                                      staticStyle:
                                                                        {
                                                                          "margin-right":
                                                                            "5px",
                                                                          "margin-top":
                                                                            "15px",
                                                                        },
                                                                      attrs: {
                                                                        text: "",
                                                                        small:
                                                                          "",
                                                                      },
                                                                      on: {
                                                                        click:
                                                                          function (
                                                                            $event
                                                                          ) {
                                                                            return _vm.openCodeViewer()
                                                                          },
                                                                      },
                                                                    },
                                                                    on
                                                                  ),
                                                                  [
                                                                    _vm.gitOpsLoading
                                                                      ? _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              VProgressCircular,
                                                                              {
                                                                                attrs:
                                                                                  {
                                                                                    indeterminate:
                                                                                      "",
                                                                                    color:
                                                                                      "primary",
                                                                                  },
                                                                              }
                                                                            ),
                                                                          ],
                                                                          1
                                                                        )
                                                                      : _c(
                                                                          "div",
                                                                          [
                                                                            _c(
                                                                              VIcon,
                                                                              [
                                                                                _vm._v(
                                                                                  " " +
                                                                                    _vm._s(
                                                                                      _vm
                                                                                        .icon
                                                                                        .code
                                                                                    )
                                                                                ),
                                                                              ]
                                                                            ),
                                                                            _vm._v(
                                                                              "\n                                                        GITOPS\n                                                    "
                                                                            ),
                                                                          ],
                                                                          1
                                                                        ),
                                                                  ]
                                                                ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        3324070842
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        VList,
                                                        _vm._l(
                                                          _vm.codeItems,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return _c(
                                                              VListItem,
                                                              {
                                                                key: index,
                                                                on: {
                                                                  click:
                                                                    function (
                                                                      $event
                                                                    ) {
                                                                      return _vm.functionSelect(
                                                                        item.title
                                                                      )
                                                                    },
                                                                },
                                                              },
                                                              [
                                                                _c(
                                                                  VListItemTitle,
                                                                  [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        item.title
                                                                      )
                                                                    ),
                                                                  ]
                                                                ),
                                                              ],
                                                              1
                                                            )
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              !_vm.parents
                                                ? _c(
                                                    VMenu,
                                                    {
                                                      staticStyle: {
                                                        margin:
                                                          "0px !important",
                                                      },
                                                      attrs: {
                                                        "open-on-hover": "",
                                                        "offset-y": "",
                                                      },
                                                      scopedSlots: _vm._u(
                                                        [
                                                          {
                                                            key: "activator",
                                                            fn: function ({
                                                              on,
                                                            }) {
                                                              return [
                                                                _vm.isReadOnlyModel
                                                                  ? _c(
                                                                      VBtn,
                                                                      {
                                                                        staticStyle:
                                                                          {
                                                                            "margin-right":
                                                                              "5px",
                                                                            "margin-top":
                                                                              "15px",
                                                                          },
                                                                        attrs: {
                                                                          small:
                                                                            "",
                                                                          text: "",
                                                                        },
                                                                        on: {
                                                                          click:
                                                                            function (
                                                                              $event
                                                                            ) {
                                                                              return _vm.saveComposition(
                                                                                "fork"
                                                                              )
                                                                            },
                                                                        },
                                                                      },
                                                                      [
                                                                        _c(
                                                                          VIcon,
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .icon
                                                                                  .fork
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                                                    FORK\n                                                "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    )
                                                                  : _c(
                                                                      VBtn,
                                                                      _vm._g(
                                                                        {
                                                                          staticStyle:
                                                                            {
                                                                              "margin-right":
                                                                                "5px",
                                                                              "margin-top":
                                                                                "15px",
                                                                            },
                                                                          attrs:
                                                                            {
                                                                              small:
                                                                                "",
                                                                              text: "",
                                                                            },
                                                                          on: {
                                                                            click:
                                                                              function (
                                                                                $event
                                                                              ) {
                                                                                return _vm.saveComposition(
                                                                                  "save"
                                                                                )
                                                                              },
                                                                          },
                                                                        },
                                                                        on
                                                                      ),
                                                                      [
                                                                        _c(
                                                                          VIcon,
                                                                          [
                                                                            _vm._v(
                                                                              _vm._s(
                                                                                _vm
                                                                                  .icon
                                                                                  .save
                                                                              )
                                                                            ),
                                                                          ]
                                                                        ),
                                                                        _vm._v(
                                                                          "\n                                                    SAVE\n                                                "
                                                                        ),
                                                                      ],
                                                                      1
                                                                    ),
                                                              ]
                                                            },
                                                          },
                                                        ],
                                                        null,
                                                        false,
                                                        345740653
                                                      ),
                                                    },
                                                    [
                                                      _c(
                                                        VList,
                                                        _vm._l(
                                                          _vm.saveItems,
                                                          function (
                                                            item,
                                                            index
                                                          ) {
                                                            return !_vm.isClazzModeling
                                                              ? _c(
                                                                  VListItem,
                                                                  {
                                                                    key: index,
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.functionSelect(
                                                                            item.title,
                                                                            index
                                                                          )
                                                                        },
                                                                    },
                                                                  },
                                                                  [
                                                                    _c(
                                                                      VListItemTitle,
                                                                      [
                                                                        _vm._v(
                                                                          _vm._s(
                                                                            item.title
                                                                          )
                                                                        ),
                                                                      ]
                                                                    ),
                                                                  ],
                                                                  1
                                                                )
                                                              : _vm._e()
                                                          }
                                                        ),
                                                        1
                                                      ),
                                                    ],
                                                    1
                                                  )
                                                : _vm._e(),
                                              _vm.isOwnModel &&
                                              _vm.isServerModel &&
                                              !_vm.isReadOnlyModel
                                                ? _c(VMenu, {
                                                    staticClass: "pa-2",
                                                    attrs: {
                                                      "offset-y": "",
                                                      "open-on-hover": "",
                                                      left: "",
                                                    },
                                                    scopedSlots: _vm._u(
                                                      [
                                                        {
                                                          key: "activator",
                                                          fn: function ({
                                                            on,
                                                          }) {
                                                            return [
                                                              _c(
                                                                VBtn,
                                                                _vm._g(
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "margin-right":
                                                                          "5px",
                                                                        "margin-top":
                                                                          "15px",
                                                                      },
                                                                    attrs: {
                                                                      text: "",
                                                                    },
                                                                    on: {
                                                                      click:
                                                                        function (
                                                                          $event
                                                                        ) {
                                                                          return _vm.openInviteUsers()
                                                                        },
                                                                    },
                                                                  },
                                                                  on
                                                                ),
                                                                [
                                                                  _c(VIcon, [
                                                                    _vm._v(
                                                                      _vm._s(
                                                                        _vm.icon
                                                                          .share
                                                                      )
                                                                    ),
                                                                  ]),
                                                                  _vm._v(
                                                                    "\n                                                    SHARE\n                                                "
                                                                  ),
                                                                ],
                                                                1
                                                              ),
                                                            ]
                                                          },
                                                        },
                                                      ],
                                                      null,
                                                      false,
                                                      3878910501
                                                    ),
                                                  })
                                                : _vm._e(),
                                            ],
                                            1
                                          ),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              )
                            : _vm._e(),
                          !_vm.isReadOnlyModel
                            ? _c(
                                VCard,
                                {
                                  staticClass: "tools",
                                  staticStyle: {
                                    top: "100px",
                                    "text-align": "center",
                                  },
                                },
                                _vm._l(
                                  _vm.elementTypes,
                                  function (category, categoryIndex) {
                                    return _c(
                                      VTooltip,
                                      {
                                        key: categoryIndex,
                                        attrs: { right: "" },
                                        scopedSlots: _vm._u(
                                          [
                                            {
                                              key: "activator",
                                              fn: function ({ on }) {
                                                return [
                                                  _c(
                                                    "span",
                                                    {
                                                      attrs: {
                                                        align: "center",
                                                        _component:
                                                          category[0].component,
                                                        _width:
                                                          category[0].width,
                                                        _height:
                                                          category[0].height,
                                                        _description:
                                                          category[0]
                                                            .description,
                                                        _label:
                                                          category[0].label,
                                                      },
                                                      on: {
                                                        mouseover: function (
                                                          $event
                                                        ) {
                                                          return _vm.changeCategory(
                                                            categoryIndex
                                                          )
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _c(
                                                        "img",
                                                        _vm._g(
                                                          {
                                                            attrs: {
                                                              height: "30px",
                                                              width: "30px",
                                                              src: category[0]
                                                                .src,
                                                            },
                                                          },
                                                          on
                                                        )
                                                      ),
                                                    ]
                                                  ),
                                                ]
                                              },
                                            },
                                          ],
                                          null,
                                          true
                                        ),
                                      },
                                      [
                                        _c("span", [
                                          _vm._v(_vm._s(category[0].component)),
                                        ]),
                                      ]
                                    )
                                  }
                                ),
                                1
                              )
                            : _vm._e(),
                          _vm._l(
                            _vm.elementTypes,
                            function (category, categoryIndex) {
                              return _c("div", { key: categoryIndex }, [
                                _vm.selectedCategoryIndex == categoryIndex
                                  ? _c(
                                      "div",
                                      _vm._l(category, function (item, key) {
                                        return _c(
                                          VTooltip,
                                          {
                                            key: key,
                                            attrs: { right: "" },
                                            scopedSlots: _vm._u(
                                              [
                                                key > 0
                                                  ? {
                                                      key: "activator",
                                                      fn: function ({ on }) {
                                                        return [
                                                          _c(
                                                            "span",
                                                            {
                                                              staticClass:
                                                                "draggable",
                                                              style:
                                                                _vm.toolStyle(
                                                                  key,
                                                                  categoryIndex,
                                                                  category.length
                                                                ),
                                                              attrs: {
                                                                align: "center",
                                                                _component:
                                                                  item.component,
                                                                _width:
                                                                  item.width,
                                                                _height:
                                                                  item.height,
                                                                _description:
                                                                  item.description,
                                                                _label:
                                                                  item.label,
                                                              },
                                                              on: {
                                                                click:
                                                                  function (
                                                                    $event
                                                                  ) {
                                                                    item.x =
                                                                      500 +
                                                                      Math.floor(
                                                                        Math.random() *
                                                                          200
                                                                      )
                                                                    item.y =
                                                                      280 +
                                                                      Math.floor(
                                                                        Math.random() *
                                                                          150
                                                                      )
                                                                    _vm.addElement(
                                                                      item
                                                                    )
                                                                  },
                                                              },
                                                            },
                                                            [
                                                              _c(
                                                                "img",
                                                                _vm._g(
                                                                  {
                                                                    staticStyle:
                                                                      {
                                                                        "vertical-align":
                                                                          "middle",
                                                                        border:
                                                                          "2 solid grey",
                                                                        "-webkit-box-shadow":
                                                                          "5px 5px 20px 0px rgba(0,0,0,0.75)",
                                                                        "-moz-box-shadow":
                                                                          "5px 5px 20px 0px rgba(0,0,0,0.40)",
                                                                        "box-shadow":
                                                                          "5px 5px 20px 0px rgba(0,0,0,0.40)",
                                                                      },
                                                                    attrs: {
                                                                      valign:
                                                                        "middle",
                                                                      onmouseover:
                                                                        "this.height=this.height*1.5;this.width=this.width*1.5;this.left=this.left-this.width*0.5;this.right=this.right-this.width*0.5;",
                                                                      onmouseout:
                                                                        "this.height=this.height/1.5;this.width=this.width/1.5;this.left=this.left+this.width*0.5;this.right=this.right+this.width*0.5;",
                                                                      height:
                                                                        "40px",
                                                                      width:
                                                                        "40px",
                                                                      src: item.src,
                                                                      border:
                                                                        "2",
                                                                    },
                                                                  },
                                                                  on
                                                                )
                                                              ),
                                                              _c(
                                                                VChip,
                                                                _vm._g({}, on),
                                                                [
                                                                  _vm._v(
                                                                    _vm._s(
                                                                      item.label
                                                                    )
                                                                  ),
                                                                ]
                                                              ),
                                                            ],
                                                            1
                                                          ),
                                                        ]
                                                      },
                                                    }
                                                  : null,
                                              ],
                                              null,
                                              true
                                            ),
                                          },
                                          [
                                            _c(
                                              VCard,
                                              {
                                                staticClass: "mx-auto",
                                                attrs: {
                                                  "max-width": "400",
                                                  "max-height": "400",
                                                  outlined: "",
                                                },
                                              },
                                              [
                                                _c(
                                                  VListItem,
                                                  {
                                                    attrs: { "three-line": "" },
                                                  },
                                                  [
                                                    _c(
                                                      VListItemContent,
                                                      [
                                                        _c(
                                                          "div",
                                                          {
                                                            staticClass:
                                                              "overline mb-4",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                category[0]
                                                                  .label
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VListItemTitle,
                                                          {
                                                            staticClass:
                                                              "headline mb-1",
                                                          },
                                                          [
                                                            _vm._v(
                                                              _vm._s(item.label)
                                                            ),
                                                          ]
                                                        ),
                                                        _c(
                                                          VListItemSubtitle,
                                                          [
                                                            _vm._v(
                                                              _vm._s(
                                                                item.description
                                                              )
                                                            ),
                                                          ]
                                                        ),
                                                      ],
                                                      1
                                                    ),
                                                    _c(
                                                      VListItemAvatar,
                                                      {
                                                        attrs: {
                                                          tile: "",
                                                          size: "80",
                                                          color: "white",
                                                        },
                                                      },
                                                      [
                                                        _c(VImg, {
                                                          attrs: {
                                                            src: item.src,
                                                          },
                                                        }),
                                                      ],
                                                      1
                                                    ),
                                                  ],
                                                  1
                                                ),
                                              ],
                                              1
                                            ),
                                          ],
                                          1
                                        )
                                      }),
                                      1
                                    )
                                  : _vm._e(),
                              ])
                            }
                          ),
                        ],
                        2
                      ),
                      _c(
                        "modal",
                        {
                          attrs: {
                            name: "codeModal",
                            height: "auto",
                            width: "80%",
                            scrollable: "",
                          },
                        },
                        [
                          _c(
                            VCard,
                            { attrs: { flat: "" } },
                            [
                              _c(
                                VCardTitle,
                                { staticStyle: { height: "90px" } },
                                [
                                  _c(
                                    VCol,
                                    { attrs: { col: 8 } },
                                    [
                                      _c("span", { staticClass: "headline" }, [
                                        _vm._v("Code Preview"),
                                      ]),
                                      _c(
                                        VBtn,
                                        {
                                          staticClass:
                                            "code-preview-left-re-size-btn",
                                          attrs: {
                                            fab: "",
                                            "x-small": "",
                                            icon: "",
                                          },
                                          on: {
                                            click: function ($event) {
                                              return _vm.codePreviewLeftReSize()
                                            },
                                          },
                                        },
                                        [
                                          _vm._v(
                                            "≡\n                                    "
                                          ),
                                        ]
                                      ),
                                    ],
                                    1
                                  ),
                                  _c(
                                    VCol,
                                    { staticStyle: { "margin-top": "-10px" } },
                                    [
                                      _c(VSelect, {
                                        staticClass: "pa-0",
                                        staticStyle: {
                                          display: "block",
                                          width: "150px",
                                          position: "absolute",
                                          right: "25px",
                                          top: "20px",
                                        },
                                        attrs: {
                                          items: _vm.templateTypes,
                                          label: "Select Template",
                                          "hide-details": "",
                                        },
                                        model: {
                                          value: _vm.template,
                                          callback: function ($$v) {
                                            _vm.template = $$v
                                          },
                                          expression: "template",
                                        },
                                      }),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(VDivider),
                              _c(
                                VCardText,
                                {
                                  staticStyle: {
                                    width: "auto",
                                    height: "auto",
                                    "margin-bottom": "-40px",
                                  },
                                },
                                [
                                  _c(
                                    VRow,
                                    {
                                      staticClass: "mb-6",
                                      attrs: { "no-gutters": "" },
                                    },
                                    [
                                      _c(
                                        VCol,
                                        {
                                          staticClass:
                                            "overflow-auto code-preview-left-re-size",
                                          staticStyle: {
                                            "border-right": "1px solid black",
                                            "max-height": "550px",
                                            display: "flex !important",
                                          },
                                          attrs: { id: "scroll-target" },
                                        },
                                        [
                                          _c(VTreeview, {
                                            staticStyle: {
                                              "text-overflow":
                                                "clip !important",
                                              width: "600px",
                                            },
                                            attrs: {
                                              items: _vm.treeList,
                                              active: _vm.openCode,
                                              activatable: "",
                                              "item-key": "key",
                                              "return-object": "",
                                              "open-all": "",
                                              transition: _vm.treeOpen,
                                              "open-on-click": "",
                                              transition: "",
                                              dense: "",
                                            },
                                            on: {
                                              "update:items": function (
                                                $event
                                              ) {
                                                _vm.treeList = $event
                                              },
                                              "update:active": function (
                                                $event
                                              ) {
                                                _vm.openCode = $event
                                              },
                                            },
                                            scopedSlots: _vm._u([
                                              {
                                                key: "prepend",
                                                fn: function ({ item, open }) {
                                                  return [
                                                    !item.file
                                                      ? _c(VIcon, [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                open
                                                                  ? "mdi-folder-open"
                                                                  : "mdi-folder"
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ])
                                                      : _c(VIcon, [
                                                          _vm._v(
                                                            "\n                                                    " +
                                                              _vm._s(
                                                                _vm.icon[
                                                                  item.file
                                                                ]
                                                              ) +
                                                              "\n                                                "
                                                          ),
                                                        ]),
                                                  ]
                                                },
                                              },
                                            ]),
                                          }),
                                        ],
                                        1
                                      ),
                                      _c(
                                        VCol,
                                        [
                                          _vm.diffCheck
                                            ? _c("code-viewer", {
                                                staticStyle: {
                                                  padding: "0 !important",
                                                },
                                                attrs: {
                                                  "diff-value": _vm.existYaml,
                                                  type: "diff",
                                                  "create-value": _vm.existYaml,
                                                },
                                                on: {
                                                  update: _vm.updatePathTmp,
                                                },
                                                model: {
                                                  value: _vm.openCode,
                                                  callback: function ($$v) {
                                                    _vm.openCode = $$v
                                                  },
                                                  expression: "openCode",
                                                },
                                              })
                                            : _vm.codeView
                                            ? _c("code-viewer", {
                                                staticStyle: {
                                                  padding: "0 !important",
                                                },
                                                model: {
                                                  value: _vm.openCode,
                                                  callback: function ($$v) {
                                                    _vm.openCode = $$v
                                                  },
                                                  expression: "openCode",
                                                },
                                              })
                                            : _vm._e(),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                "v-card-action",
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { text: "" },
                                      on: {
                                        click: function ($event) {
                                          return _vm.endDiffCheck()
                                        },
                                      },
                                    },
                                    [_vm._v("확인")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VSnackbar,
                        {
                          attrs: {
                            color: _vm.snackbar.color,
                            "multi-line": _vm.snackbar.mode === "multi-line",
                            timeout: _vm.snackbar.timeout,
                            vertical: _vm.snackbar.mode === "vertical",
                          },
                          model: {
                            value: _vm.snackbar.show,
                            callback: function ($$v) {
                              _vm.$set(_vm.snackbar, "show", $$v)
                            },
                            expression: "snackbar.show",
                          },
                        },
                        [
                          _c("div", {
                            domProps: { innerHTML: _vm._s(_vm.snackbar.text) },
                          }),
                          _c(
                            VRow,
                            {
                              staticStyle: { margin: "auto" },
                              attrs: { justify: "end" },
                            },
                            [
                              _c(
                                VBtn,
                                {
                                  attrs: { dark: "" },
                                  on: {
                                    click: function ($event) {
                                      _vm.snackbar.show = false
                                    },
                                  },
                                },
                                [
                                  _vm._v(
                                    "\n                                Close\n                            "
                                  ),
                                ]
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "290" },
                          model: {
                            value: _vm.generateZipDialog,
                            callback: function ($$v) {
                              _vm.generateZipDialog = $$v
                            },
                            expression: "generateZipDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, { staticClass: "headline" }, [
                                _vm._v("Generate Zip Archive"),
                              ]),
                              _c(
                                VCardText,
                                [
                                  _c(VSelect, {
                                    staticClass: "pa-0",
                                    attrs: {
                                      items: _vm.templateTypes,
                                      label: "Select Template",
                                      "hide-details": "",
                                    },
                                    model: {
                                      value: _vm.template,
                                      callback: function ($$v) {
                                        _vm.template = $$v
                                      },
                                      expression: "template",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  !_vm.isDownloading
                                    ? _c(
                                        "div",
                                        [
                                          _c(
                                            VBtn,
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  _vm.generateZipDialog = false
                                                },
                                              },
                                            },
                                            [_vm._v("Cancel")]
                                          ),
                                          _c(
                                            VBtn,
                                            {
                                              attrs: { text: "" },
                                              on: {
                                                click: function ($event) {
                                                  return _vm.generateZip()
                                                },
                                              },
                                            },
                                            [_vm._v("Download")]
                                          ),
                                        ],
                                        1
                                      )
                                    : _c(VProgressCircular, {
                                        attrs: {
                                          indeterminate: "",
                                          color: "primary",
                                        },
                                      }),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "350" },
                          model: {
                            value: _vm.deployDialog,
                            callback: function ($$v) {
                              _vm.deployDialog = $$v
                            },
                            expression: "deployDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, { staticClass: "headline" }, [
                                _vm._v("Deploy"),
                              ]),
                              _c(
                                VCardText,
                                [
                                  _c(VTextField, {
                                    attrs: { label: "cluster" },
                                    model: {
                                      value: _vm.clusterInfo.name,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.clusterInfo, "name", $$v)
                                      },
                                      expression: "clusterInfo.name",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: { label: "API Server" },
                                    model: {
                                      value: _vm.clusterInfo.apiServer,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.clusterInfo,
                                          "apiServer",
                                          $$v
                                        )
                                      },
                                      expression: "clusterInfo.apiServer",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: { label: "Namespace" },
                                    model: {
                                      value: _vm.clusterInfo.namespace,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.clusterInfo,
                                          "namespace",
                                          $$v
                                        )
                                      },
                                      expression: "clusterInfo.namespace",
                                    },
                                  }),
                                  _c("div", { staticClass: "subtitle" }, [
                                    _vm._v(
                                      "Namespace 미 입력시 default로 설정됩니다."
                                    ),
                                  ]),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "red darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.deployDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.deployReady()
                                        },
                                      },
                                    },
                                    [_vm._v("Deploy")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: {
                            persistent: "",
                            fullscreen: "",
                            "hide-overlay": "",
                            transition: "dialog-bottom-transition",
                          },
                          model: {
                            value: _vm.clusterDialog,
                            callback: function ($$v) {
                              _vm.clusterDialog = $$v
                            },
                            expression: "clusterDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VToolbar,
                                { attrs: { dark: "", color: "primary" } },
                                [
                                  _c(VToolbarTitle, [
                                    _vm._v("Manage Clusters"),
                                  ]),
                                  _c(VSpacer),
                                  _c(
                                    VToolbarItems,
                                    [
                                      _c(
                                        VBtn,
                                        {
                                          attrs: { icon: "", dark: "" },
                                          on: {
                                            click: function ($event) {
                                              _vm.clusterDialog = false
                                            },
                                          },
                                        },
                                        [_c(VIcon, [_vm._v("mdi-close")])],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VList,
                                { attrs: { "three-line": "", subheader: "" } },
                                [
                                  _c(
                                    VListItem,
                                    [
                                      _c(
                                        VListItemContent,
                                        [
                                          _c("clusters", {
                                            on: {
                                              close: function ($event) {
                                                _vm.clusterDialog = false
                                              },
                                            },
                                            model: {
                                              value: _vm.clusterInfo,
                                              callback: function ($$v) {
                                                _vm.clusterInfo = $$v
                                              },
                                              expression: "clusterInfo",
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "700" },
                          model: {
                            value: _vm.commandDialog,
                            callback: function ($$v) {
                              _vm.commandDialog = $$v
                            },
                            expression: "commandDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(
                                VCardTitle,
                                [
                                  _c(
                                    VTabs,
                                    {
                                      staticClass: "mx-auto",
                                      model: {
                                        value: _vm.commandTab,
                                        callback: function ($$v) {
                                          _vm.commandTab = $$v
                                        },
                                        expression: "commandTab",
                                      },
                                    },
                                    _vm._l(_vm.commandTabs, function (tab) {
                                      return _c(VTab, { key: tab }, [
                                        _vm._v(_vm._s(tab)),
                                      ])
                                    }),
                                    1
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VCardText,
                                { staticClass: "px-5 py-2" },
                                _vm._l(_vm.commandList, function (obj, idx) {
                                  return _c(
                                    "div",
                                    { key: idx },
                                    [
                                      _vm.commandTabs[_vm.commandTab].includes(
                                        obj.label
                                      )
                                        ? _c(VTextField, {
                                            staticStyle: {
                                              "max-height": "65px",
                                            },
                                            attrs: {
                                              value: obj.command,
                                              label: obj.label,
                                              readonly: "",
                                              outlined: "",
                                              "append-icon": "mdi-content-copy",
                                              id: "copyCommand" + idx,
                                            },
                                            on: {
                                              "click:append": function (
                                                $event
                                              ) {
                                                return _vm.commandCopy(
                                                  obj.command,
                                                  idx
                                                )
                                              },
                                            },
                                          })
                                        : _vm._e(),
                                      _vm.commandTabs[_vm.commandTab].includes(
                                        obj.label
                                      )
                                        ? _c(
                                            "div",
                                            {
                                              staticStyle: {
                                                "margin-bottom": "10px",
                                                "padding-left": "490px",
                                              },
                                            },
                                            [
                                              _c(
                                                VBtn,
                                                {
                                                  attrs: {
                                                    color: "primary",
                                                    text: "",
                                                  },
                                                  on: {
                                                    click: function ($event) {
                                                      return _vm.runCommand(
                                                        obj.command
                                                      )
                                                    },
                                                  },
                                                },
                                                [_vm._v("Run in terminal")]
                                              ),
                                            ],
                                            1
                                          )
                                        : _vm._e(),
                                    ],
                                    1
                                  )
                                }),
                                0
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.commandDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("Close")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        "modal",
                        {
                          staticStyle: { overflow: "scroll" },
                          attrs: {
                            name: "yamlModal",
                            height: "auto",
                            width: "80%",
                          },
                        },
                        [
                          _c(
                            VCard,
                            { attrs: { flat: "" } },
                            [
                              _c(
                                VCardTitle,
                                { staticStyle: { position: "sticky" } },
                                [
                                  _c(VCol, { attrs: { col: 8 } }, [
                                    _c("span", { staticClass: "headline" }, [
                                      _vm._v("From Local YAML"),
                                    ]),
                                  ]),
                                ],
                                1
                              ),
                              _c(VDivider),
                              _c(
                                VCardText,
                                {
                                  staticStyle: {
                                    width: "auto",
                                    height: "auto",
                                  },
                                },
                                [
                                  _c("local-yaml-editor", {
                                    staticStyle: {
                                      width: "100%",
                                      height: "100%",
                                    },
                                    model: {
                                      value: _vm.localYamlText,
                                      callback: function ($$v) {
                                        _vm.localYamlText = $$v
                                      },
                                      expression: "localYamlText",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c("text-reader", {
                                    staticClass:
                                      "v-btn v-btn--contained v-size--default",
                                    staticStyle: {
                                      color: "#1976d2",
                                      background: "none",
                                      "border-radius": "none",
                                    },
                                    attrs: {
                                      label: "Upload File",
                                      fileName: _vm.fileName,
                                      plainText: _vm.localYamlText,
                                      importType: "yaml",
                                    },
                                    on: {
                                      "update:fileName": function ($event) {
                                        _vm.fileName = $event
                                      },
                                      "update:file-name": function ($event) {
                                        _vm.fileName = $event
                                      },
                                      "update:plainText": function ($event) {
                                        _vm.localYamlText = $event
                                      },
                                      "update:plain-text": function ($event) {
                                        _vm.localYamlText = $event
                                      },
                                      load: function ($event) {
                                        return _vm.loadYaml($event)
                                      },
                                    },
                                  }),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { text: "", color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          $event.preventDefault()
                                          return _vm.drawFromYaml.apply(
                                            null,
                                            arguments
                                          )
                                        },
                                      },
                                    },
                                    [
                                      _vm.reverseYaml
                                        ? _c(
                                            "div",
                                            [
                                              _c(VProgressCircular, {
                                                attrs: {
                                                  indeterminate: "",
                                                  color: "primary",
                                                },
                                              }),
                                            ],
                                            1
                                          )
                                        : _c("div", [_vm._v("Reverse")]),
                                    ]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          staticClass: "v-dialog v-dialog--active",
                          attrs: { "hide-overlay": "", "max-width": "50%" },
                          model: {
                            value: _vm.isSearch,
                            callback: function ($$v) {
                              _vm.isSearch = $$v
                            },
                            expression: "isSearch",
                          },
                        },
                        [
                          _c(
                            "div",
                            {
                              staticStyle: {
                                color: "gray",
                                "font-size": "small",
                                "margin-left": "5px",
                              },
                            },
                            [_vm._v(" cmd + p / ctrl + p")]
                          ),
                          _c(VAutocomplete, {
                            staticClass: "v-input-prepend-outer-prepend-icon",
                            staticStyle: { "font-size": "xx-large" },
                            attrs: {
                              rounded: "",
                              items: _vm.mergeElementTypes,
                              "prepend-icon": "mdi-magnify",
                              filter: _vm.customFilter,
                              "item-text": "label",
                              "return-object": "",
                              "auto-select-first": "",
                              autofocus: "",
                            },
                            on: { keydown: _vm.searchKeyDown },
                            scopedSlots: _vm._u([
                              {
                                key: "item",
                                fn: function (data) {
                                  return [
                                    [
                                      _c(VListItemAvatar, [
                                        _c("img", {
                                          attrs: { src: data.item.src },
                                        }),
                                      ]),
                                      _c(
                                        VListItemContent,
                                        [
                                          _c(VListItemTitle, {
                                            domProps: {
                                              innerHTML: _vm._s(
                                                data.item.label
                                              ),
                                            },
                                          }),
                                        ],
                                        1
                                      ),
                                    ],
                                  ]
                                },
                              },
                            ]),
                            model: {
                              value: _vm.searchKeyword,
                              callback: function ($$v) {
                                _vm.searchKeyword = $$v
                              },
                              expression: "searchKeyword",
                            },
                          }),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          attrs: { "max-width": "500" },
                          model: {
                            value: _vm.definedDialog,
                            callback: function ($$v) {
                              _vm.definedDialog = $$v
                            },
                            expression: "definedDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, { staticClass: "headline" }, [
                                _vm._v("Add CRD"),
                              ]),
                              _c(
                                VCardText,
                                { staticStyle: { overflow: "hidden" } },
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "kind",
                                      autofocus: "",
                                      rules: [
                                        (value) => !!value || "Required.",
                                      ],
                                    },
                                    model: {
                                      value: _vm.definedCrd.kind,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.definedCrd, "kind", $$v)
                                      },
                                      expression: "definedCrd.kind",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Icon",
                                      hint: "EX) https://raw.githubusercontent.com/kubernetes/community/master/icons/png/resources/unlabeled/pod-128.png",
                                      rules: [
                                        (value) => !!value || "Required.",
                                      ],
                                    },
                                    model: {
                                      value: _vm.definedCrd.icon,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.definedCrd, "icon", $$v)
                                      },
                                      expression: "definedCrd.icon",
                                    },
                                  }),
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Color",
                                      rules: [
                                        (value) => !!value || "Required.",
                                      ],
                                    },
                                    model: {
                                      value: _vm.definedCrd.color,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.definedCrd, "color", $$v)
                                      },
                                      expression: "definedCrd.color",
                                    },
                                  }),
                                  _c("span", [_vm._v("CRD Yaml")]),
                                  _c("MonacoEditor", {
                                    staticClass: "editor",
                                    attrs: {
                                      theme: "vs-dark",
                                      language: "yaml",
                                    },
                                    model: {
                                      value: _vm.definedCrd.yaml,
                                      callback: function ($$v) {
                                        _vm.$set(_vm.definedCrd, "yaml", $$v)
                                      },
                                      expression: "definedCrd.yaml",
                                    },
                                  }),
                                ],
                                1
                              ),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "green darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          return _vm.addDefinedCrd(
                                            _vm.definedCrd
                                          )
                                        },
                                      },
                                    },
                                    [_vm._v("ADD CRD")]
                                  ),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: {
                                        color: "red darken-1",
                                        text: "",
                                      },
                                      on: {
                                        click: function ($event) {
                                          _vm.definedDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("Cancel")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VDialog,
                        {
                          model: {
                            value: _vm.settingGitInfoDialog,
                            callback: function ($$v) {
                              _vm.settingGitInfoDialog = $$v
                            },
                            expression: "settingGitInfoDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, [_vm._v("GitOps Configure")]),
                              _c(
                                VCardText,
                                [
                                  _c(
                                    VStepper,
                                    {
                                      model: {
                                        value: _vm.step,
                                        callback: function ($$v) {
                                          _vm.step = $$v
                                        },
                                        expression: "step",
                                      },
                                    },
                                    [
                                      _vm.checkGitLogin
                                        ? _c(
                                            VStepperHeader,
                                            [
                                              _vm._l(
                                                _vm.gitSteps,
                                                function (n, idx) {
                                                  return [
                                                    _c(
                                                      VStepperStep,
                                                      {
                                                        key: `${idx}-step`,
                                                        attrs: {
                                                          complete:
                                                            _vm.step > idx,
                                                          step: idx + 1,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(n) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                    idx !== _vm.step
                                                      ? _c(VDivider, {
                                                          key: n,
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          )
                                        : _c(
                                            VStepperHeader,
                                            [
                                              _vm._l(
                                                _vm.googleSteps,
                                                function (n, idx) {
                                                  return [
                                                    _c(
                                                      VStepperStep,
                                                      {
                                                        key: `${idx}-step`,
                                                        attrs: {
                                                          complete:
                                                            _vm.step > idx,
                                                          step: idx + 1,
                                                        },
                                                      },
                                                      [
                                                        _vm._v(
                                                          "\n                                                " +
                                                            _vm._s(n) +
                                                            "\n                                            "
                                                        ),
                                                      ]
                                                    ),
                                                    idx <
                                                    _vm.googleSteps.length - 1
                                                      ? _c(VDivider, {
                                                          key: idx,
                                                        })
                                                      : _vm._e(),
                                                  ]
                                                }
                                              ),
                                            ],
                                            2
                                          ),
                                      _vm.checkGitLogin
                                        ? _c(
                                            VStepperItems,
                                            [
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "1" } },
                                                [
                                                  _c(VSelect, {
                                                    attrs: {
                                                      width: "100%",
                                                      items:
                                                        _vm.argoServerLists,
                                                      "item-text":
                                                        _vm.checkName,
                                                      "item-value": "namespace",
                                                      "return-object": "",
                                                      label: "Argo Server",
                                                      hint: "namespace/deployment",
                                                      "persistent-hint": "",
                                                    },
                                                    model: {
                                                      value: _vm.argoServerInfo,
                                                      callback: function ($$v) {
                                                        _vm.argoServerInfo = $$v
                                                      },
                                                      expression:
                                                        "argoServerInfo",
                                                    },
                                                  }),
                                                  _c(VSelect, {
                                                    attrs: {
                                                      width: "100%",
                                                      items: _vm.argoCdLists,
                                                      "item-text":
                                                        _vm.checkName,
                                                      "item-value": "namespace",
                                                      "return-object": "",
                                                      label: "Argo Server",
                                                      hint: "namespace/deployment",
                                                      "persistent-hint": "",
                                                    },
                                                    model: {
                                                      value: _vm.argoCdInfo,
                                                      callback: function ($$v) {
                                                        _vm.argoCdInfo = $$v
                                                      },
                                                      expression: "argoCdInfo",
                                                    },
                                                  }),
                                                  _c(VSpacer),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.step =
                                                            _vm.step + 1
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Continue\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "2" } },
                                                [
                                                  _c(
                                                    VRadioGroup,
                                                    {
                                                      attrs: { row: "" },
                                                      model: {
                                                        value:
                                                          _vm.repositoryType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.repositoryType =
                                                            $$v
                                                        },
                                                        expression:
                                                          "repositoryType",
                                                      },
                                                    },
                                                    [
                                                      _c(VRadio, {
                                                        attrs: {
                                                          label: "url",
                                                          value: "url",
                                                        },
                                                      }),
                                                      _c(VRadio, {
                                                        attrs: {
                                                          label: "select",
                                                          value: "select",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.repositoryType == "select"
                                                    ? _c(VSelect, {
                                                        attrs: {
                                                          items:
                                                            _vm.repositoryList,
                                                          "item-text": "name",
                                                          "item-value": "url",
                                                          label:
                                                            "Select Repository",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.gitInfo.url,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.gitInfo,
                                                              "url",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "gitInfo.url",
                                                        },
                                                      })
                                                    : _c(VTextField, {
                                                        attrs: {
                                                          label:
                                                            "Repository URL",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.gitInfo.url,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.gitInfo,
                                                              "url",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "gitInfo.url",
                                                        },
                                                      }),
                                                  _c(VSpacer),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.step =
                                                            _vm.step + 1
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Continue\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "3" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "YAML Path",
                                                    },
                                                    model: {
                                                      value: _vm.gitInfo.path,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.gitInfo,
                                                          "path",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "gitInfo.path",
                                                    },
                                                  }),
                                                  _c(VSpacer),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getNamespaceList()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Continue\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "4" } },
                                                [
                                                  _c(VSelect, {
                                                    attrs: {
                                                      items: _vm.namespaceList,
                                                      label:
                                                        "Select Repository",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.gitInfo.namespace,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.gitInfo,
                                                          "namespace",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "gitInfo.namespace",
                                                    },
                                                  }),
                                                  _c(VSpacer),
                                                  _c(VSpacer),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        color: "green darken-1",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setGitRepository()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Save")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          )
                                        : _c(
                                            VStepperItems,
                                            [
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "1" } },
                                                [
                                                  _c(VSelect, {
                                                    attrs: {
                                                      width: "100%",
                                                      items:
                                                        _vm.argoServerLists,
                                                      "item-text":
                                                        _vm.checkName,
                                                      "item-value": "namespace",
                                                      "return-object": "",
                                                      label: "Argo Server",
                                                      hint: "namespace/deployment",
                                                      "persistent-hint": "",
                                                    },
                                                    model: {
                                                      value: _vm.argoServerInfo,
                                                      callback: function ($$v) {
                                                        _vm.argoServerInfo = $$v
                                                      },
                                                      expression:
                                                        "argoServerInfo",
                                                    },
                                                  }),
                                                  _c(VSelect, {
                                                    attrs: {
                                                      width: "100%",
                                                      items: _vm.argoCdLists,
                                                      "item-text":
                                                        _vm.checkName,
                                                      "item-value": "namespace",
                                                      "return-object": "",
                                                      label: "Argo Server",
                                                      hint: "namespace/deployment",
                                                      "persistent-hint": "",
                                                    },
                                                    model: {
                                                      value: _vm.argoCdInfo,
                                                      callback: function ($$v) {
                                                        _vm.argoCdInfo = $$v
                                                      },
                                                      expression: "argoCdInfo",
                                                    },
                                                  }),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "2" } },
                                                [
                                                  _c(
                                                    VRadioGroup,
                                                    {
                                                      attrs: { row: "" },
                                                      model: {
                                                        value: _vm.gitInfo.type,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.$set(
                                                            _vm.gitInfo,
                                                            "type",
                                                            $$v
                                                          )
                                                        },
                                                        expression:
                                                          "gitInfo.type",
                                                      },
                                                    },
                                                    [
                                                      _c(VRadio, {
                                                        attrs: {
                                                          label: "Github",
                                                          value: "github",
                                                        },
                                                      }),
                                                      _c(VRadio, {
                                                        attrs: {
                                                          disabled: "",
                                                          label: "Gitlab",
                                                          value: "gitlab",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Git Username",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.gitInfo.username,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.gitInfo,
                                                          "username",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "gitInfo.username",
                                                    },
                                                  }),
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "Git AccessToken",
                                                    },
                                                    model: {
                                                      value: _vm.gitInfo.token,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.gitInfo,
                                                          "token",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "gitInfo.token",
                                                    },
                                                  }),
                                                  _c(
                                                    "div",
                                                    { staticClass: "body-1" },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Github 유저명과, GitAccessToken을 입력하여줍니다.\n                                                자세한 발급 방법은 "
                                                      ),
                                                      _c(
                                                        "a",
                                                        {
                                                          attrs: {
                                                            href: "https://github.com/TheOpenCloudEngine/msaschool/wiki/Github-Personal-Access-Token-%EB%B0%9C%ED%96%89-%EB%B0%A9%EB%B2%95",
                                                            target: "_blank",
                                                          },
                                                        },
                                                        [_vm._v("이곳")]
                                                      ),
                                                      _vm._v(
                                                        "을 참조하세요.\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                  _c(
                                                    "div",
                                                    {
                                                      staticStyle: {
                                                        "margin-top": "15px",
                                                      },
                                                    },
                                                    [
                                                      _c(VSpacer),
                                                      _c(
                                                        VBtn,
                                                        {
                                                          attrs: {
                                                            color: "primary",
                                                          },
                                                          on: {
                                                            click: function (
                                                              $event
                                                            ) {
                                                              return _vm.getGitRepoList()
                                                            },
                                                          },
                                                        },
                                                        [
                                                          _vm._v(
                                                            "\n                                                    Continue\n                                                "
                                                          ),
                                                        ]
                                                      ),
                                                    ],
                                                    1
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "3" } },
                                                [
                                                  _c(
                                                    VRadioGroup,
                                                    {
                                                      attrs: { row: "" },
                                                      model: {
                                                        value:
                                                          _vm.repositoryType,
                                                        callback: function (
                                                          $$v
                                                        ) {
                                                          _vm.repositoryType =
                                                            $$v
                                                        },
                                                        expression:
                                                          "repositoryType",
                                                      },
                                                    },
                                                    [
                                                      _c(VRadio, {
                                                        attrs: {
                                                          label: "url",
                                                          value: "url",
                                                        },
                                                      }),
                                                      _c(VRadio, {
                                                        attrs: {
                                                          label: "select",
                                                          value: "select",
                                                        },
                                                      }),
                                                    ],
                                                    1
                                                  ),
                                                  _vm.repositoryType == "select"
                                                    ? _c(VSelect, {
                                                        attrs: {
                                                          items:
                                                            _vm.repositoryList,
                                                          "item-text": "name",
                                                          "item-value": "url",
                                                          label:
                                                            "Select Repository",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.gitInfo.url,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.gitInfo,
                                                              "url",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "gitInfo.url",
                                                        },
                                                      })
                                                    : _c(VTextField, {
                                                        attrs: {
                                                          label:
                                                            "Repository URL",
                                                        },
                                                        model: {
                                                          value:
                                                            _vm.gitInfo.url,
                                                          callback: function (
                                                            $$v
                                                          ) {
                                                            _vm.$set(
                                                              _vm.gitInfo,
                                                              "url",
                                                              $$v
                                                            )
                                                          },
                                                          expression:
                                                            "gitInfo.url",
                                                        },
                                                      }),
                                                  _c(VSpacer),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          _vm.step =
                                                            _vm.step + 1
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Continue\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "4" } },
                                                [
                                                  _c(VTextField, {
                                                    attrs: {
                                                      label: "YAML Path",
                                                    },
                                                    model: {
                                                      value: _vm.gitInfo.path,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.gitInfo,
                                                          "path",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "gitInfo.path",
                                                    },
                                                  }),
                                                  _c(VSpacer),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        color: "primary",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.getNamespaceList()
                                                        },
                                                      },
                                                    },
                                                    [
                                                      _vm._v(
                                                        "\n                                                Continue\n                                            "
                                                      ),
                                                    ]
                                                  ),
                                                ],
                                                1
                                              ),
                                              _c(
                                                VStepperContent,
                                                { attrs: { step: "5" } },
                                                [
                                                  _c(VSelect, {
                                                    attrs: {
                                                      items: _vm.namespaceList,
                                                      label:
                                                        "Select Repository",
                                                    },
                                                    model: {
                                                      value:
                                                        _vm.gitInfo.namespace,
                                                      callback: function ($$v) {
                                                        _vm.$set(
                                                          _vm.gitInfo,
                                                          "namespace",
                                                          $$v
                                                        )
                                                      },
                                                      expression:
                                                        "gitInfo.namespace",
                                                    },
                                                  }),
                                                  _c(VSpacer),
                                                  _c(VSpacer),
                                                  _c(
                                                    VBtn,
                                                    {
                                                      attrs: {
                                                        color: "green darken-1",
                                                        text: "",
                                                      },
                                                      on: {
                                                        click: function (
                                                          $event
                                                        ) {
                                                          return _vm.setGitRepository()
                                                        },
                                                      },
                                                    },
                                                    [_vm._v("Save")]
                                                  ),
                                                ],
                                                1
                                              ),
                                            ],
                                            1
                                          ),
                                    ],
                                    1
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("model-canvas-share-dialog", {
                        attrs: {
                          showDialog: _vm.inviteDialog,
                          checkPublic: _vm.showPublicModel,
                          canvas: _vm.canvas,
                          canvasComponentName: "kubernetes-model-canvas",
                        },
                        on: {
                          all: _vm.invitePublic,
                          apply: _vm.applyInviteUsers,
                          close: _vm.closeInviteUsers,
                          add: _vm.addInviteUser,
                          remove: _vm.removeInviteUser,
                        },
                        model: {
                          value: _vm.inviteLists,
                          callback: function ($$v) {
                            _vm.inviteLists = $$v
                          },
                          expression: "inviteLists",
                        },
                      }),
                      _c("model-storage-dialog", {
                        attrs: {
                          condition: _vm.storageCondition,
                          showDialog: _vm.showStorageDialog,
                        },
                        on: {
                          save: _vm.saveModel,
                          fork: _vm.forkModel,
                          backup: _vm.backupModel,
                          close: _vm.storageDialogCancel,
                        },
                      }),
                      _c(
                        VDialog,
                        {
                          staticStyle: { width: "400px !important" },
                          attrs: { width: "400px", "hide-overlay": "" },
                          model: {
                            value: _vm.argoDialog,
                            callback: function ($$v) {
                              _vm.argoDialog = $$v
                            },
                            expression: "argoDialog",
                          },
                        },
                        [
                          _c(
                            VCard,
                            [
                              _c(VCardTitle, [
                                _vm._v(
                                  "\n                                Managed Argo-server\n                            "
                                ),
                              ]),
                              _c(VCardText),
                              _c(
                                VCardActions,
                                [
                                  _c(VSpacer),
                                  _c(
                                    VBtn,
                                    {
                                      attrs: { color: "primary" },
                                      on: {
                                        click: function ($event) {
                                          _vm.argoDialog = false
                                        },
                                      },
                                    },
                                    [_vm._v("ok")]
                                  ),
                                ],
                                1
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c("modeler-image-generator", {
                        ref: "modeler-image-generator",
                      }),
                    ],
                    1
                  ),
                ]),
              ]
            },
            proxy: true,
          },
          {
            key: "two",
            fn: function () {
              return [
                _vm.embedded
                  ? _c("CodeGenerator", {
                      attrs: {
                        isOwnModel: _vm.isOwnModel,
                        isServerModel: _vm.isServerModel,
                        projectInformation: _vm.information,
                        projectName: _vm.projectName,
                        modelInitLoad: _vm.initLoad,
                        modelingProjectId: _vm.projectId,
                        asyncCodeForValue: false,
                        callCodeForValue: _vm.changedTemplateCode,
                        oldTreeHashLists: _vm.oldTreeHashLists,
                        newTreeHashLists: _vm.newTreeHashLists,
                        projectVersion: _vm.projectVersion,
                        embeddedK8s: _vm.embedded,
                        "canvas-name": "kubernetes-model-canvas",
                      },
                      on: {
                        "update:oldTreeHashLists": function ($event) {
                          _vm.oldTreeHashLists = $event
                        },
                        "update:old-tree-hash-lists": function ($event) {
                          _vm.oldTreeHashLists = $event
                        },
                        "update:newTreeHashLists": function ($event) {
                          _vm.newTreeHashLists = $event
                        },
                        "update:new-tree-hash-lists": function ($event) {
                          _vm.newTreeHashLists = $event
                        },
                        changedByMe: _vm.settingChangedByMe,
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    })
                  : _c("kube-code-generator", {
                      attrs: {
                        isOwnModel: _vm.isOwnModel,
                        isServerModel: _vm.isServerModel,
                        projectInformation: _vm.information,
                        projectName: _vm.projectName,
                        modelInitLoad: _vm.initLoad,
                        modelingProjectId: _vm.projectId,
                        asyncCodeForValue: false,
                        callCodeForValue: _vm.changedTemplateCode,
                        oldTreeHashLists: _vm.oldTreeHashLists,
                        newTreeHashLists: _vm.newTreeHashLists,
                        projectVersion: _vm.projectVersion,
                        "canvas-name": "kubernetes-model-canvas",
                      },
                      on: {
                        "update:oldTreeHashLists": function ($event) {
                          _vm.oldTreeHashLists = $event
                        },
                        "update:old-tree-hash-lists": function ($event) {
                          _vm.oldTreeHashLists = $event
                        },
                        "update:newTreeHashLists": function ($event) {
                          _vm.newTreeHashLists = $event
                        },
                        "update:new-tree-hash-lists": function ($event) {
                          _vm.newTreeHashLists = $event
                        },
                        changedByMe: _vm.settingChangedByMe,
                        editModelData: _vm.editModelData,
                      },
                      model: {
                        value: _vm.value,
                        callback: function ($$v) {
                          _vm.value = $$v
                        },
                        expression: "value",
                      },
                    }),
              ]
            },
            proxy: true,
          },
        ]),
      }),
      _vm._l(_vm.filteredMouseEventHandlers, function (otherMouseEvent, email) {
        return _c(
          "div",
          { key: email },
          [
            _c("MouseCursorComponent", {
              attrs: { mouseEvent: otherMouseEvent, email: email },
            }),
          ],
          1
        )
      }),
      _vm.projectId
        ? _c("GeneratorUI", {
            ref: "generatorUI",
            attrs: {
              isGenerated: _vm.embedded,
              projectId: _vm.projectId,
              modelValue: _vm.value,
              defaultInputData: _vm.defaultGeneratorUiInputData,
            },
            on: {
              createModel: _vm.createModel,
              clearModelValue: _vm.clearModelValue,
              modificateModel: _vm.modificateModel,
            },
          })
        : _vm._e(),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }