import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCol } from 'vuetify/lib/components/VGrid';
import { VLabel } from 'vuetify/lib/components/VLabel';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VRow } from 'vuetify/lib/components/VGrid';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: { img: _vm.img, isReadOnly: _vm.isReadOnly },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VTextField, {
            attrs: { label: "Image", disabled: _vm.isReadOnly },
            model: {
              value:
                _vm.value.object.spec.runLatest.configuration.revisionTemplate
                  .spec.container.image,
              callback: function ($$v) {
                _vm.$set(
                  _vm.value.object.spec.runLatest.configuration.revisionTemplate
                    .spec.container,
                  "image",
                  $$v
                )
              },
              expression:
                "value.object.spec.runLatest.configuration.revisionTemplate.spec.container.image",
            },
          }),
          _c(VLabel, [_vm._v("Environment")]),
          _c(
            VRow,
            [
              _c(
                VCol,
                { staticClass: "py-0", attrs: { cols: "5" } },
                [
                  _c(VTextField, {
                    attrs: { label: "Key", disabled: _vm.isReadOnly },
                    model: {
                      value: _vm.dataKey,
                      callback: function ($$v) {
                        _vm.dataKey = $$v
                      },
                      expression: "dataKey",
                    },
                  }),
                ],
                1
              ),
              _c(
                VCol,
                { staticClass: "py-0" },
                [
                  _c(VTextField, {
                    attrs: { label: "Value", disabled: _vm.isReadOnly },
                    on: {
                      keyup: function ($event) {
                        if (
                          !$event.type.indexOf("key") &&
                          _vm._k(
                            $event.keyCode,
                            "enter",
                            13,
                            $event.key,
                            "Enter"
                          )
                        )
                          return null
                        return _vm.addData(_vm.dataKey, _vm.dataValue)
                      },
                    },
                    model: {
                      value: _vm.dataValue,
                      callback: function ($$v) {
                        _vm.dataValue = $$v
                      },
                      expression: "dataValue",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            VRow,
            { attrs: { justify: "end" } },
            [
              _c(
                VBtn,
                {
                  staticClass: "mx-5",
                  attrs: {
                    color: "primary",
                    rounded: "",
                    dark: "",
                    disabled: _vm.isReadOnly,
                  },
                  on: {
                    click: function ($event) {
                      return _vm.addData(_vm.dataKey, _vm.dataValue)
                    },
                  },
                },
                [_vm._v("Add Env")]
              ),
            ],
            1
          ),
          _c("br"),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }