var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _vm.isView
        ? _c("edge-element", {
            attrs: {
              selectable: "",
              connectable: !_vm.canvas.isReadOnlyModel,
              deletable: !_vm.canvas.isReadOnlyModel,
              moveable: !_vm.canvas.isReadOnlyModel,
              vertices: _vm.vertices,
              from: _vm.value.from,
              to: _vm.value.to,
              _style: _vm.style_,
              label: _vm.value.name,
              customMoveActionExist: _vm.canvas.isCustomMoveExist,
            },
            on: {
              "update:vertices": function ($event) {
                _vm.vertices = $event
              },
              "update:from": function ($event) {
                return _vm.$set(_vm.value, "from", $event)
              },
              "update:to": function ($event) {
                return _vm.$set(_vm.value, "to", $event)
              },
              dblclick: _vm.openPanel,
              selectShape: _vm.selectedActivity,
              deSelectShape: _vm.deSelectedActivity,
              removeShape: _vm.onRemoveShape,
              customRelationMoveAction: _vm.delayedRelationMove,
            },
          })
        : _vm._e(),
      _vm.propertyPanel
        ? _c("relation-panel", {
            attrs: {
              titleName: "VirtualService To DestinationRule",
              isReadOnly: !_vm.isEditElement,
            },
            on: { close: _vm.closePanel },
            model: {
              value: _vm.value,
              callback: function ($$v) {
                _vm.value = $$v
              },
              expression: "value",
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }