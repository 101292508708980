import { VLabel } from 'vuetify/lib/components/VLabel';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: {
        img: _vm.img,
        isReadOnly: _vm.isReadOnly,
        "validation-lists": _vm.validationLists,
      },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VTextField, {
            attrs: { label: "Image", disabled: _vm.isReadOnly },
            model: {
              value:
                _vm.value.object.spec.jobTemplate.spec.template.spec
                  .containers[0].image,
              callback: function ($$v) {
                _vm.$set(
                  _vm.value.object.spec.jobTemplate.spec.template.spec
                    .containers[0],
                  "image",
                  $$v
                )
              },
              expression:
                "value.object.spec.jobTemplate.spec.template.spec.containers[0].image",
            },
          }),
          _c(VSelect, {
            attrs: {
              label: "restartPolicy",
              disabled: _vm.isReadOnly,
              items: _vm.restartPolicyList,
            },
            model: {
              value:
                _vm.value.object.spec.jobTemplate.spec.template.spec
                  .restartPolicy,
              callback: function ($$v) {
                _vm.$set(
                  _vm.value.object.spec.jobTemplate.spec.template.spec,
                  "restartPolicy",
                  $$v
                )
              },
              expression:
                "value.object.spec.jobTemplate.spec.template.spec.restartPolicy",
            },
          }),
          _c(VLabel, [_vm._v("Schedule")]),
          _c("v-cron-field", {
            attrs: { isReadOnly: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.schedule,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec, "schedule", $$v)
              },
              expression: "value.object.spec.schedule",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }