import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCardTitle } from 'vuetify/lib/components/VCard';
import { VContainer } from 'vuetify/lib/components/VGrid';
import { VImg } from 'vuetify/lib/components/VImg';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VContainer,
    [
      _vm.value[0]
        ? _c(
            VCard,
            [
              _vm.value[0]
                ? _c(VCardTitle, [
                    _vm._v(
                      "\n            " +
                        _vm._s(_vm.value[0].name) +
                        " 1\n        "
                    ),
                  ])
                : _vm._e(),
              _vm.value[0].name.includes(".png")
                ? _c(
                    VCardText,
                    {
                      staticClass: "overflow-auto",
                      staticStyle: { "max-height": "600px" },
                      attrs: { id: "scroll-target" },
                    },
                    [
                      _c(VImg, {
                        staticClass: "grey lighten-2",
                        attrs: {
                          src: _vm.cmOption,
                          "aspect-ratio": "1",
                          "max-width": "500",
                          "max-height": "300",
                        },
                      }),
                    ],
                    1
                  )
                : _vm.value[0].name.includes(".jpg")
                ? _c(
                    VCardText,
                    {
                      staticClass: "overflow-auto",
                      staticStyle: { "max-height": "600px" },
                      attrs: { id: "scroll-target" },
                    },
                    [
                      _c(VImg, {
                        staticClass: "grey lighten-2",
                        attrs: {
                          src: _vm.cmOption,
                          "aspect-ratio": "1",
                          "max-width": "500",
                          "max-height": "300",
                        },
                      }),
                    ],
                    1
                  )
                : _c(
                    VCardText,
                    {
                      staticClass: "overflow-auto",
                      staticStyle: { "max-height": "600px" },
                      attrs: { id: "scroll-target" },
                    },
                    [
                      _vm.value[0]
                        ? _c("codemirror", {
                            ref: "codemirror",
                            staticStyle: { width: "580px" },
                            attrs: {
                              value: _vm.value[0].code,
                              options: _vm.cmOption,
                              height: "700",
                            },
                            on: {
                              ready: _vm.onCmReady,
                              Focus: _vm.onCmFocus,
                              input: _vm.onCmCodeChange,
                            },
                          })
                        : _vm._e(),
                    ],
                    1
                  ),
            ],
            1
          )
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }