import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: { img: _vm.img, isReadOnly: _vm.isReadOnly },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-layout" },
        [
          _c(
            VLayout,
            { attrs: { wrap: "" } },
            [
              _c(
                VFlex,
                { staticStyle: { width: "230px" }, attrs: { shrink: "" } },
                [
                  _c(
                    VCard,
                    { attrs: { flat: "" } },
                    [
                      _c(
                        VCardText,
                        [
                          _c(VTextField, {
                            attrs: {
                              label: "Name",
                              autofocus: "",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.value.object.metadata.generateName,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.value.object.metadata,
                                  "generateName",
                                  $$v
                                )
                              },
                              expression: "value.object.metadata.generateName",
                            },
                          }),
                          _c(VTextField, {
                            attrs: {
                              label: "Entrypoint",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.value.object.spec.entrypoint,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.value.object.spec,
                                  "entrypoint",
                                  $$v
                                )
                              },
                              expression: "value.object.spec.entrypoint",
                            },
                          }),
                          _c(VTextField, {
                            attrs: {
                              label: "Template Name",
                              disabled: _vm.isReadOnly,
                            },
                            model: {
                              value: _vm.value.object.spec.templates[0].name,
                              callback: function ($$v) {
                                _vm.$set(
                                  _vm.value.object.spec.templates[0],
                                  "name",
                                  $$v
                                )
                              },
                              expression: "value.object.spec.templates[0].name",
                            },
                          }),
                          _vm.value.workflowType == "Steps" ||
                          _vm.value.workflowType == "Dag"
                            ? _c(
                                VBtn,
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    disabled: _vm.isReadOnly,
                                  },
                                  on: { click: _vm.addProperty },
                                },
                                [
                                  _vm.value.workflowType == "Steps"
                                    ? _c("label", [_vm._v("ADD STEP")])
                                    : _vm.value.workflowType == "Dag"
                                    ? _c("label", [_vm._v("ADD TASK")])
                                    : _vm._e(),
                                ]
                              )
                            : _vm._e(),
                          _c("kube-attr-field", {
                            attrs: { isReadOnly: _vm.isReadOnly },
                            model: {
                              value: _vm.value,
                              callback: function ($$v) {
                                _vm.value = $$v
                              },
                              expression: "value",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              ),
              _c(
                VFlex,
                [
                  _c("kube-yaml-editor", {
                    attrs: { isReadOnly: _vm.isReadOnly },
                    model: {
                      value: _vm.value.object,
                      callback: function ($$v) {
                        _vm.$set(_vm.value, "object", $$v)
                      },
                      expression: "value.object",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }