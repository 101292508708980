import { VCard } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VSwitch } from 'vuetify/lib/components/VSwitch';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return !_vm.commandView
    ? _c(
        "div",
        [
          _c(
            "div",
            [
              _c(VSwitch, {
                staticClass: "justify-end",
                staticStyle: {
                  padding: "0 15px 0 0",
                  margin: "0",
                  float: "left",
                },
                attrs: { label: _vm.isJson ? "Yaml" : "Json" },
                model: {
                  value: _vm.isJson,
                  callback: function ($$v) {
                    _vm.isJson = $$v
                  },
                  expression: "isJson",
                },
              }),
              _c(VCheckbox, {
                attrs: { label: "Auto format" },
                on: { change: _vm.formatYaml },
                model: {
                  value: _vm.autoFormat,
                  callback: function ($$v) {
                    _vm.autoFormat = $$v
                  },
                  expression: "autoFormat",
                },
              }),
            ],
            1
          ),
          _c(
            VCard,
            { attrs: { disabled: _vm.isReadOnly } },
            [
              !_vm.isJson && _vm.autoFormat
                ? _c("MonacoEditor", {
                    staticClass: "editor",
                    attrs: { theme: "vs-dark", language: "yaml" },
                    model: {
                      value: _vm.yamlText,
                      callback: function ($$v) {
                        _vm.yamlText = $$v
                      },
                      expression: "yamlText",
                    },
                  })
                : _vm._e(),
              !_vm.isJson && !_vm.autoFormat
                ? _c("MonacoEditor", {
                    staticClass: "editor",
                    attrs: { theme: "vs-dark", language: "plaintext" },
                    model: {
                      value: _vm.temp_text,
                      callback: function ($$v) {
                        _vm.temp_text = $$v
                      },
                      expression: "temp_text",
                    },
                  })
                : _vm._e(),
              _vm.isJson
                ? _c("vue-json-editor", {
                    attrs: { expandedOnStart: true },
                    model: {
                      value: _vm.value,
                      callback: function ($$v) {
                        _vm.value = $$v
                      },
                      expression: "value",
                    },
                  })
                : _vm._e(),
            ],
            1
          ),
        ],
        1
      )
    : _vm._e()
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }