import { render, staticRenderFns } from "./KubeRelationPanel.vue?vue&type=template&id=7b4ca1dd"
import script from "./KubeRelationPanel.vue?vue&type=script&lang=js"
export * from "./KubeRelationPanel.vue?vue&type=script&lang=js"
import style0 from "./KubeRelationPanel.vue?vue&type=style&index=0&id=7b4ca1dd&lang=scss&rel=stylesheet%2Fscss"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

/* hot reload */
if (module.hot) {
  var api = require("/Users/kibumpark/Desktop/uEngine-project/platform/node_modules/vue-hot-reload-api/dist/index.js")
  api.install(require('vue'))
  if (api.compatible) {
    module.hot.accept()
    if (!api.isRecorded('7b4ca1dd')) {
      api.createRecord('7b4ca1dd', component.options)
    } else {
      api.reload('7b4ca1dd', component.options)
    }
    module.hot.accept("./KubeRelationPanel.vue?vue&type=template&id=7b4ca1dd", function () {
      api.rerender('7b4ca1dd', {
        render: render,
        staticRenderFns: staticRenderFns
      })
    })
  }
}
component.options.__file = "src/components/designer/k8s-modeling/element/KubeRelationPanel.vue"
export default component.exports