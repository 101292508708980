import { VIcon } from 'vuetify/lib/components/VIcon';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: {
        img: _vm.img,
        isReadOnly: _vm.isReadOnly,
        "validation-lists": _vm.validationLists,
      },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VSelect, {
            attrs: {
              label: "Subjects Kind",
              disabled: _vm.isReadOnly,
              items: _vm.kindList,
            },
            scopedSlots: _vm._u([
              {
                key: "append-outer",
                fn: function () {
                  return [
                    _c(
                      VIcon,
                      {
                        attrs: { small: "" },
                        on: {
                          click: function ($event) {
                            return _vm.desDocOpen("#referring-to-subjects")
                          },
                        },
                      },
                      [_vm._v("mdi-help-circle-outline")]
                    ),
                  ]
                },
                proxy: true,
              },
            ]),
            model: {
              value: _vm.value.object.subjects[0].kind,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.subjects[0], "kind", $$v)
              },
              expression: "value.object.subjects[0].kind",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Subjects Name", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.subjects[0].name,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.subjects[0], "name", $$v)
              },
              expression: "value.object.subjects[0].name",
            },
          }),
          _c(VSelect, {
            attrs: {
              label: "Role Kind",
              disabled: _vm.isReadOnly,
              items: _vm.roleList,
            },
            model: {
              value: _vm.value.object.roleRef.kind,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.roleRef, "kind", $$v)
              },
              expression: "value.object.roleRef.kind",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Role Name", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.roleRef.name,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.roleRef, "name", $$v)
              },
              expression: "value.object.roleRef.name",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }