import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: {
        img: _vm.img,
        isReadOnly: _vm.isReadOnly,
        "validation-lists": _vm.validationLists,
      },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _vm.value.status
            ? _c(
                VTabs,
                {
                  model: {
                    value: _vm.activeTab,
                    callback: function ($$v) {
                      _vm.activeTab = $$v
                    },
                    expression: "activeTab",
                  },
                },
                _vm._l(_vm.tabItems, function (tab, idx) {
                  return _c(
                    VTab,
                    { key: idx },
                    [_c(VListItemTitle, [_vm._v(_vm._s(tab))])],
                    1
                  )
                }),
                1
              )
            : _c(VListItemTitle, { staticClass: "headline" }, [
                _vm._v(
                  "\n             " + _vm._s(_vm.value._type) + "\n         "
                ),
              ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-layout" },
        [
          _vm.value.status && _vm.activeTab == 0
            ? _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VFlex,
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c("tree-view", {
                                attrs: {
                                  data: _vm.status,
                                  options: {
                                    rootObjectKey: "status",
                                  },
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VFlex,
                    { staticStyle: { width: "180px" }, attrs: { shrink: "" } },
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c(VTextField, {
                                attrs: {
                                  label: "Name",
                                  autofocus: "",
                                  disabled: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.object.metadata.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.metadata,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression: "value.object.metadata.name",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "App Name",
                                  disabled: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.object.spec.selector.app,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.spec.selector,
                                      "app",
                                      $$v
                                    )
                                  },
                                  expression: "value.object.spec.selector.app",
                                },
                              }),
                              _c("kube-number-field", {
                                attrs: {
                                  label: "Port",
                                  isReadOnly: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.object.spec.ports[0].port,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.spec.ports[0],
                                      "port",
                                      $$v
                                    )
                                  },
                                  expression: "value.object.spec.ports[0].port",
                                },
                              }),
                              _c("kube-number-field", {
                                attrs: {
                                  label: "Target Port",
                                  isReadOnly: _vm.isReadOnly,
                                },
                                model: {
                                  value:
                                    _vm.value.object.spec.ports[0].targetPort,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.spec.ports[0],
                                      "targetPort",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.object.spec.ports[0].targetPort",
                                },
                              }),
                              _c(VSelect, {
                                attrs: {
                                  label: "Type",
                                  disabled: _vm.isReadOnly,
                                  items: _vm.typeList,
                                },
                                scopedSlots: _vm._u([
                                  {
                                    key: "append-outer",
                                    fn: function () {
                                      return [
                                        _c(
                                          VIcon,
                                          {
                                            attrs: { small: "" },
                                            on: {
                                              click: function ($event) {
                                                return _vm.desDocOpen(
                                                  "#publishing-services-service-types"
                                                )
                                              },
                                            },
                                          },
                                          [
                                            _vm._v(
                                              "\n                                     mdi-help-circle-outline\n                                 "
                                            ),
                                          ]
                                        ),
                                      ]
                                    },
                                    proxy: true,
                                  },
                                ]),
                                model: {
                                  value: _vm.value.object.spec.type,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value.object.spec, "type", $$v)
                                  },
                                  expression: "value.object.spec.type",
                                },
                              }),
                              _c("kube-attr-field", {
                                attrs: { isReadOnly: _vm.isReadOnly },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    [
                      _c("kube-yaml-editor", {
                        attrs: { isReadOnly: _vm.isReadOnly },
                        model: {
                          value: _vm.value.object,
                          callback: function ($$v) {
                            _vm.$set(_vm.value, "object", $$v)
                          },
                          expression: "value.object",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }