import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTab } from 'vuetify/lib/components/VTabs';
import { VTabs } from 'vuetify/lib/components/VTabs';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: { img: _vm.img, isReadOnly: _vm.isReadOnly },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(
            VTabs,
            {
              model: {
                value: _vm.tabIdx,
                callback: function ($$v) {
                  _vm.tabIdx = $$v
                },
                expression: "tabIdx",
              },
            },
            _vm._l(_vm.tabs, function (tab, idx) {
              return _c(
                VTab,
                { key: idx },
                [_c(VListItemTitle, [_vm._v(_vm._s(tab))])],
                1
              )
            }),
            1
          ),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-layout" },
        [
          _vm.activeTab == "Property"
            ? _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VFlex,
                    { staticStyle: { width: "230px" }, attrs: { shrink: "" } },
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c(VTextField, {
                                attrs: {
                                  label: "Name",
                                  autofocus: "",
                                  disabled: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.object.metadata.name,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.object.metadata,
                                      "name",
                                      $$v
                                    )
                                  },
                                  expression: "value.object.metadata.name",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "Host",
                                  disabled: _vm.isReadOnly,
                                },
                                model: {
                                  value: _vm.value.object.spec.host,
                                  callback: function ($$v) {
                                    _vm.$set(_vm.value.object.spec, "host", $$v)
                                  },
                                  expression: "value.object.spec.host",
                                },
                              }),
                              _c(
                                VBtn,
                                {
                                  staticClass: "mb-4",
                                  attrs: {
                                    color: "primary",
                                    text: "",
                                    disabled: _vm.isReadOnly,
                                  },
                                  on: { click: _vm.addSubset },
                                },
                                [
                                  _vm._v(
                                    "\n                            ADD SUBSET\n                        "
                                  ),
                                ]
                              ),
                              _c("kube-attr-field", {
                                attrs: { isReadOnly: _vm.isReadOnly },
                                model: {
                                  value: _vm.value,
                                  callback: function ($$v) {
                                    _vm.value = $$v
                                  },
                                  expression: "value",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    [
                      _c("kube-yaml-editor", {
                        attrs: { isReadOnly: _vm.isReadOnly },
                        model: {
                          value: _vm.propertyData,
                          callback: function ($$v) {
                            _vm.propertyData = $$v
                          },
                          expression: "propertyData",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              )
            : _c(
                VLayout,
                { attrs: { wrap: "" } },
                [
                  _c(
                    VFlex,
                    { staticStyle: { width: "200px" }, attrs: { shrink: "" } },
                    [
                      _c(
                        VCard,
                        { attrs: { flat: "" } },
                        [
                          _c(
                            VCardText,
                            [
                              _c(VCheckbox, {
                                attrs: { label: "Edit TrafficPolicy" },
                                model: {
                                  value: _vm.editTrafficPolicy,
                                  callback: function ($$v) {
                                    _vm.editTrafficPolicy = $$v
                                  },
                                  expression: "editTrafficPolicy",
                                },
                              }),
                              _c("kube-number-field", {
                                attrs: {
                                  label: "Http1 Max Pending Requests",
                                  isReadOnly:
                                    _vm.isReadOnly || !_vm.editTrafficPolicy,
                                },
                                model: {
                                  value:
                                    _vm.value.trafficPolicy.connectionPool.http
                                      .http1MaxPendingRequests,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.trafficPolicy.connectionPool
                                        .http,
                                      "http1MaxPendingRequests",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.trafficPolicy.connectionPool.http.http1MaxPendingRequests",
                                },
                              }),
                              _c("kube-number-field", {
                                attrs: {
                                  label: "Max Requests Per Connection",
                                  isReadOnly:
                                    _vm.isReadOnly || !_vm.editTrafficPolicy,
                                },
                                model: {
                                  value:
                                    _vm.value.trafficPolicy.connectionPool.http
                                      .maxRequestsPerConnection,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.trafficPolicy.connectionPool
                                        .http,
                                      "maxRequestsPerConnection",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.trafficPolicy.connectionPool.http.maxRequestsPerConnection",
                                },
                              }),
                              _c("kube-number-field", {
                                attrs: {
                                  label: "Max Connections",
                                  isReadOnly:
                                    _vm.isReadOnly || !_vm.editTrafficPolicy,
                                },
                                model: {
                                  value:
                                    _vm.value.trafficPolicy.connectionPool.tcp
                                      .maxConnections,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.trafficPolicy.connectionPool
                                        .tcp,
                                      "maxConnections",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.trafficPolicy.connectionPool.tcp.maxConnections",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "Base Ejection Time",
                                  disabled:
                                    _vm.isReadOnly || !_vm.editTrafficPolicy,
                                  type: "number",
                                  suffix: "s",
                                },
                                model: {
                                  value: _vm.baseEjectionTime,
                                  callback: function ($$v) {
                                    _vm.baseEjectionTime = $$v
                                  },
                                  expression: "baseEjectionTime",
                                },
                              }),
                              _c("kube-number-field", {
                                attrs: {
                                  label: "Consecutive Errors",
                                  isReadOnly:
                                    _vm.isReadOnly || !_vm.editTrafficPolicy,
                                },
                                model: {
                                  value:
                                    _vm.value.trafficPolicy.outlierDetection
                                      .consecutiveErrors,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.trafficPolicy.outlierDetection,
                                      "consecutiveErrors",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.trafficPolicy.outlierDetection.consecutiveErrors",
                                },
                              }),
                              _c(VTextField, {
                                attrs: {
                                  label: "Interval",
                                  disabled:
                                    _vm.isReadOnly || !_vm.editTrafficPolicy,
                                  type: "number",
                                  suffix: "s",
                                },
                                model: {
                                  value: _vm.interval,
                                  callback: function ($$v) {
                                    _vm.interval = $$v
                                  },
                                  expression: "interval",
                                },
                              }),
                              _c("kube-number-field", {
                                attrs: {
                                  label: "Max Ejection Percent",
                                  isReadOnly:
                                    _vm.isReadOnly || !_vm.editTrafficPolicy,
                                },
                                model: {
                                  value:
                                    _vm.value.trafficPolicy.outlierDetection
                                      .maxEjectionPercent,
                                  callback: function ($$v) {
                                    _vm.$set(
                                      _vm.value.trafficPolicy.outlierDetection,
                                      "maxEjectionPercent",
                                      $$v
                                    )
                                  },
                                  expression:
                                    "value.trafficPolicy.outlierDetection.maxEjectionPercent",
                                },
                              }),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                  _c(
                    VFlex,
                    [
                      _c("kube-yaml-editor", {
                        attrs: {
                          isReadOnly: _vm.isReadOnly || !_vm.editTrafficPolicy,
                        },
                        model: {
                          value: _vm.trafficPolicy,
                          callback: function ($$v) {
                            _vm.trafficPolicy = $$v
                          },
                          expression: "trafficPolicy",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }