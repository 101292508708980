import { VBtn } from 'vuetify/lib/components/VBtn';
import { VIcon } from 'vuetify/lib/components/VIcon';
import ClickOutside from 'vuetify/lib/directives/click-outside';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c("div", [
    _c(
      "ul",
      {
        directives: [
          {
            def: ClickOutside,
            name: "click-outside",
            rawName: "v-click-outside",
            value: _vm.onClickOutside,
            expression: "onClickOutside",
          },
        ],
        staticClass: "vue-simple-context-menu",
        attrs: { id: _vm.elementId },
      },
      _vm._l(_vm.options, function (option, index) {
        return _c(
          "li",
          {
            key: index,
            staticClass: "vue-simple-context-menu__item",
            class: [
              option.class,
              option.type === "divider"
                ? "vue-simple-context-menu__divider"
                : "",
            ],
            on: {
              click: function ($event) {
                return _vm.optionClicked(option)
              },
            },
          },
          [
            _c("span", { domProps: { innerHTML: _vm._s(option.name) } }),
            _vm._v("\n                \n            "),
            option.name != "Terminal Open"
              ? _c(
                  VBtn,
                  {
                    staticStyle: { "margin-left": "auto" },
                    attrs: { icon: "" },
                    on: {
                      click: function ($event) {
                        $event.stopPropagation()
                        return _vm.beforeCommandCopy(option)
                      },
                    },
                  },
                  [
                    _c(VIcon, { attrs: { small: "" } }, [
                      _vm._v("mdi-content-copy"),
                    ]),
                  ],
                  1
                )
              : _vm._e(),
          ],
          1
        )
      }),
      0
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }