import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VSelect } from 'vuetify/lib/components/VSelect';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: { img: _vm.img, isReadOnly: _vm.isReadOnly },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VTextField, {
            attrs: { label: "Host", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.host,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec, "host", $$v)
              },
              expression: "value.object.spec.host",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Port Name", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.port.name,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.port, "name", $$v)
              },
              expression: "value.object.spec.port.name",
            },
          }),
          _c("kube-number-field", {
            attrs: { label: "Port Number", isReadOnly: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.port.number,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.port, "number", $$v)
              },
              expression: "value.object.spec.port.number",
            },
          }),
          _c(VSelect, {
            attrs: {
              label: "Protocol",
              items: _vm.protocolList,
              disabled: _vm.isReadOnly,
            },
            model: {
              value: _vm.value.object.spec.port.protocol,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.port, "protocol", $$v)
              },
              expression: "value.object.spec.port.protocol",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }