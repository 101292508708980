<template>
</template>

<script>
    import CodeGenerator from "../modeling/CodeGenerator";

    export default {
        name: 'kubernetes-code-generator',
        mixins:[CodeGenerator],
        components:{
        },
        data() {
            return {
            }
        }
    }
</script>