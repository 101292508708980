import { VCheckbox } from 'vuetify/lib/components/VCheckbox';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "kubernetes-common-panel",
    {
      attrs: {
        img: _vm.img,
        isReadOnly: _vm.isReadOnly,
        "validation-lists": _vm.validationLists,
      },
      on: { openDesDoc: _vm.desDocOpen, close: _vm.closePanel },
      model: {
        value: _vm.value,
        callback: function ($$v) {
          _vm.value = $$v
        },
        expression: "value",
      },
    },
    [
      _c(
        "template",
        { slot: "headline" },
        [
          _c(VListItemTitle, { staticClass: "headline" }, [
            _vm._v("\n            " + _vm._s(_vm.value._type) + "\n        "),
          ]),
        ],
        1
      ),
      _c("template", { slot: "descriptionText" }, [
        _c("span", [_vm._v(_vm._s(_vm.descriptionText))]),
      ]),
      _c(
        "template",
        { slot: "edit-property" },
        [
          _c(VTextField, {
            attrs: {
              label: "Grop",
              hint: "Ex) crd.example.com",
              disabled: _vm.isReadOnly,
            },
            model: {
              value: _vm.value.object.spec.group,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec, "group", $$v)
              },
              expression: "value.object.spec.group",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Version", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.versions[0].name,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.versions[0], "name", $$v)
              },
              expression: "value.object.spec.versions[0].name",
            },
          }),
          _c(VCheckbox, {
            attrs: { label: "Served" },
            model: {
              value: _vm.value.object.spec.versions[0].served,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.versions[0], "served", $$v)
              },
              expression: "value.object.spec.versions[0].served",
            },
          }),
          _c(VCheckbox, {
            attrs: { label: "Storage" },
            model: {
              value: _vm.value.object.spec.versions[0].storage,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.versions[0], "storage", $$v)
              },
              expression: "value.object.spec.versions[0].storage",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Scope", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.scope,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec, "scope", $$v)
              },
              expression: "value.object.spec.scope",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Plural", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.names.plural,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.names, "plural", $$v)
              },
              expression: "value.object.spec.names.plural",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Singular", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.names.singular,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.names, "singular", $$v)
              },
              expression: "value.object.spec.names.singular",
            },
          }),
          _c(VTextField, {
            attrs: { label: "Kind", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.names.kind,
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.names, "kind", $$v)
              },
              expression: "value.object.spec.names.kind",
            },
          }),
          _c(VTextField, {
            attrs: { label: "ShortNames", disabled: _vm.isReadOnly },
            model: {
              value: _vm.value.object.spec.names.shortNames[0],
              callback: function ($$v) {
                _vm.$set(_vm.value.object.spec.names.shortNames, 0, $$v)
              },
              expression: "value.object.spec.names.shortNames[0]",
            },
          }),
        ],
        1
      ),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }