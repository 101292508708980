import { VTextField } from 'vuetify/lib/components/VTextField';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(VTextField, {
        attrs: { label: "Image", disabled: _vm.isReadOnly },
        model: {
          value: _vm.value.spec.template.spec.containers[0].image,
          callback: function ($$v) {
            _vm.$set(_vm.value.spec.template.spec.containers[0], "image", $$v)
          },
          expression: "value.spec.template.spec.containers[0].image",
        },
      }),
      _c("kube-number-field", {
        attrs: { label: "Target Port", isReadOnly: _vm.isReadOnly },
        model: {
          value:
            _vm.value.spec.template.spec.containers[0].ports[0].containerPort,
          callback: function ($$v) {
            _vm.$set(
              _vm.value.spec.template.spec.containers[0].ports[0],
              "containerPort",
              $$v
            )
          },
          expression:
            "value.spec.template.spec.containers[0].ports[0].containerPort",
        },
      }),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }