import { VBtn } from 'vuetify/lib/components/VBtn';
import { VCard } from 'vuetify/lib/components/VCard';
import { VCardText } from 'vuetify/lib/components/VCard';
import { VFlex } from 'vuetify/lib/components/VGrid';
import { VIcon } from 'vuetify/lib/components/VIcon';
import { VLayout } from 'vuetify/lib/components/VGrid';
import { VList } from 'vuetify/lib/components/VList';
import { VListGroup } from 'vuetify/lib/components/VList';
import { VListItem } from 'vuetify/lib/components/VList';
import { VListItemAvatar } from 'vuetify/lib/components/VList';
import { VListItemTitle } from 'vuetify/lib/components/VList';
import { VNavigationDrawer } from 'vuetify/lib/components/VNavigationDrawer';
import { VTextField } from 'vuetify/lib/components/VTextField';
import { VTooltip } from 'vuetify/lib/components/VTooltip';

var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    VLayout,
    { attrs: { wrap: "" } },
    [
      _c(
        VNavigationDrawer,
        {
          staticClass: "hide-scroll",
          staticStyle: { "z-index": "999" },
          style: { width: 800 },
          attrs: { absolute: "", permanent: "", right: "" },
        },
        [
          _c(
            VList,
            { staticClass: "pa-1" },
            [
              _c(
                VListItem,
                [
                  _c(VListItemAvatar, [
                    _c("img", { attrs: { src: _vm.img } }),
                  ]),
                  _vm._t("headline"),
                  _c(
                    VTooltip,
                    {
                      attrs: { left: "" },
                      scopedSlots: _vm._u([
                        {
                          key: "activator",
                          fn: function ({ on }) {
                            return [
                              _c(
                                VBtn,
                                {
                                  attrs: { icon: "" },
                                  nativeOn: {
                                    click: function ($event) {
                                      return _vm.closePanel()
                                    },
                                  },
                                },
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { color: "grey lighten-1" } },
                                    [_vm._v("mdi-close")]
                                  ),
                                ],
                                1
                              ),
                              _c(
                                VBtn,
                                _vm._g(
                                  {
                                    attrs: { icon: "" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.openDoc()
                                      },
                                    },
                                  },
                                  on
                                ),
                                [
                                  _c(
                                    VIcon,
                                    { attrs: { color: "grey lighten-1" } },
                                    [_vm._v("mdi-information")]
                                  ),
                                ],
                                1
                              ),
                            ]
                          },
                        },
                      ]),
                    },
                    [_vm._t("descriptionText")],
                    2
                  ),
                ],
                2
              ),
            ],
            1
          ),
          _c(
            VList,
            { staticClass: "pt-0", attrs: { dense: "", flat: "" } },
            [
              _vm.isValidationLists
                ? _c(
                    VCardText,
                    [
                      _vm._t("md-title", function () {
                        return [
                          _c(
                            "div",
                            { staticStyle: { "margin-left": "-17px" } },
                            [
                              _c(VListGroup, {
                                attrs: { value: _vm.openValidationLists },
                                nativeOn: {
                                  click: function ($event) {
                                    _vm.openValidationLists =
                                      !_vm.openValidationLists
                                  },
                                },
                                scopedSlots: _vm._u(
                                  [
                                    {
                                      key: "activator",
                                      fn: function () {
                                        return [
                                          _c(
                                            VIcon,
                                            {
                                              staticStyle: {
                                                "margin-right": "2%",
                                              },
                                              attrs: {
                                                color:
                                                  _vm.validationLevelIcon[
                                                    _vm.validationLists[0].level
                                                  ].color,
                                              },
                                            },
                                            [
                                              _vm._v(
                                                _vm._s(
                                                  _vm.validationLevelIcon[
                                                    _vm.validationLists[0].level
                                                  ].icon
                                                )
                                              ),
                                            ]
                                          ),
                                          _c(VListItemTitle, [
                                            _vm._v(
                                              _vm._s(
                                                _vm.representativeValidation
                                              )
                                            ),
                                          ]),
                                        ]
                                      },
                                      proxy: true,
                                    },
                                  ],
                                  null,
                                  false,
                                  3021440218
                                ),
                              }),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _vm._e(),
              _vm._t("edit-layout", function () {
                return [
                  _c(
                    VLayout,
                    { attrs: { wrap: "" } },
                    [
                      _c(
                        VFlex,
                        {
                          staticStyle: { width: "230px" },
                          attrs: { shrink: "" },
                        },
                        [
                          _c(
                            VCard,
                            { attrs: { flat: "" } },
                            [
                              _c(
                                VCardText,
                                [
                                  _c(VTextField, {
                                    attrs: {
                                      label: "Name",
                                      autofocus: "",
                                      disabled: _vm.isReadOnly,
                                    },
                                    model: {
                                      value: _vm.value.object.metadata.name,
                                      callback: function ($$v) {
                                        _vm.$set(
                                          _vm.value.object.metadata,
                                          "name",
                                          $$v
                                        )
                                      },
                                      expression: "value.object.metadata.name",
                                    },
                                  }),
                                  _vm._t("edit-property"),
                                  _c("kube-attr-field", {
                                    attrs: { isReadOnly: _vm.isReadOnly },
                                    model: {
                                      value: _vm.value,
                                      callback: function ($$v) {
                                        _vm.value = $$v
                                      },
                                      expression: "value",
                                    },
                                  }),
                                ],
                                2
                              ),
                            ],
                            1
                          ),
                        ],
                        1
                      ),
                      _c(
                        VFlex,
                        [
                          _c("kube-yaml-editor", {
                            attrs: { isReadOnly: _vm.isReadOnly },
                            model: {
                              value: _vm.value.object,
                              callback: function ($$v) {
                                _vm.$set(_vm.value, "object", $$v)
                              },
                              expression: "value.object",
                            },
                          }),
                        ],
                        1
                      ),
                    ],
                    1
                  ),
                ]
              }),
            ],
            2
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }